import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-loader-button',
  templateUrl: './loader-button.component.html',
  styleUrls: ['./loader-button.component.css']
})
export class LoaderButtonComponent {
  @Input() text: string;
  @Input() disabled = false;
  @Input() icon: string;
  @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();
  isLoading = false;

  sendRequest(): void {
    this.isLoading = true;
    this.buttonClicked.emit();
  }

  requestFinished(){
    this.isLoading = false;
  }
}
