import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './_common/auth-interceptor';
import { AccessPointComponent } from './access-point/access-point.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataServiceDirectoryComponent } from './data-service-directory/data-service-directory.component';
import { EidasCallbackComponent } from './eidas/eidas-callback/eidas-callback.component';
import { EidasComponent } from './eidas/eidas-component/eidas-component.component';
import { LoaderButtonComponent } from './_common/loader-button/loader-button.component';
import { MatDividerModule } from '@angular/material/divider';
import { EvidenceBrokerComponent } from './evidence-broker/evidence-broker.component';
import { HomeComponent } from './home/home.component';
import { CommonModule, DatePipe } from '@angular/common';
import { SedeComponent } from './sede/sede.component';
import { CookieService } from 'ngx-cookie-service';
import { RequirementComponent } from './_common/requirement/requirement.component';
import { LocateAndRequestEvidenceComponent } from './locate-and-request-evidence/locate-and-request-evidence.component';
import { PreviewSpaceComponent } from './preview-space/preview-space.component';
import { ReloadDirective } from './_directives/reload.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MatTableModule } from '@angular/material/table';
import { AgreementComponent } from './agreement/agreement.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatSelectModule } from '@angular/material/select';
import { SessionTimerComponent } from './_common/session-timer/session-timer.component';
import { SessionTimerPipe } from './_common/session-timer/session-timer.pipe';
import { ModalDialogComponent } from './_common/modal-dialog/modal-dialog.component';
import { HeaderComponent } from './_common/header/header.component';
import { MultiLanguageStringPipe } from './_model/multi-language-string';
import { UserSedeComponent } from './user-sede/user-sede.component';
import { LoginMaintenanceComponent } from './login-maintenance/login-maintenance.component';
import { LocateEvidencePreviewComponent } from './locate-evidence-preview/locate-evidence-preview.component';
import { PoliticaPrivacidadComponent } from './_common/legal_info/politica-privacidad/politica-privacidad.component';
import { PoliticaCookiesComponent } from './_common/legal_info/politica-cookies/politica-cookies.component';
import { AvisoLegalComponent } from './_common/legal_info/aviso-legal/aviso-legal.component';

export function HttpLoaderFActory(http: HttpClient) {
	return new TranslateHttpLoader(http)
}

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		EvidenceBrokerComponent,
		AccessPointComponent,
		DataServiceDirectoryComponent,
		LoaderButtonComponent,
		EidasComponent,
		EidasCallbackComponent,
		SedeComponent,
		RequirementComponent,
		LocateAndRequestEvidenceComponent,
		PreviewSpaceComponent,
		ReloadDirective,
		AgreementComponent,
		SessionTimerComponent,
		SessionTimerPipe,
		ModalDialogComponent,
		HeaderComponent,
		MultiLanguageStringPipe,
  UserSedeComponent,
  LoginMaintenanceComponent,
  LocateEvidencePreviewComponent,
  PoliticaPrivacidadComponent,
  PoliticaCookiesComponent,
  AvisoLegalComponent,
	],
	imports: [
		AppRoutingModule,
		BrowserModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		MatTabsModule,
		MatInputModule,
		MatFormFieldModule,
		MatDividerModule,
		CommonModule,
		DatePipe,
		MatDialogModule,
		NgxExtendedPdfViewerModule,
		MatTableModule,
		TranslateModule.forRoot ( {
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFActory,
				deps: [HttpClient]
			}
		}),
		MatSelectModule,
		MatFormFieldModule
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		DatePipe,
		CookieService,
		MultiLanguageStringPipe
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
