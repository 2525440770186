import { Injectable } from '@angular/core';
import { EidasService } from './eidas-service.service';
import { StateService } from './state.service';
import { EidasUser } from '../_model/eidasUser';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class JwtUtilsService {

  constructor(private eidasService: EidasService, private stateService: StateService, private cookieService: CookieService) {
    if (this.isLogged()) {
        this.saveCurrentUser(JSON.stringify(this.getCurrentUser()));
    }
   }

  public getTokenFromSessionStorage(): string {
    return this.cookieService.get("token");
  }

  public isLogged(): boolean {
    return this.cookieService.get("token") !== '';
  }

  public saveTokenInSessionStorage(token: string): void {
    this.cookieService.set("token", token, { expires: new Date(new Date().getTime() +  1000 * 60 * 60),path: '/'});
  }

  saveCurrentUser(user: string): void {
    const eidasUser: EidasUser = (JSON.parse(user));
    this.cookieService.set("currentUser", user, { expires: new Date(new Date().getTime() +  1000 * 60 * 60),path: '/'});
    this.stateService.setCurrentUserName(eidasUser.firstName + " " + eidasUser.familyName);
    this.stateService.setCurrentUser(eidasUser);
  }

  public getCurrentUser(): any {
    if (this.isLogged()) return JSON.parse(this.cookieService.get("currentUser")) as EidasUser;
  }

  public saveDocumentCounter(documentCounter: string){
    this.cookieService.set("docuCount", documentCounter, {path: '/'});
  }

  public getDocumentCounter(){
    return JSON.parse(this.cookieService.get("docuCount"))
  }

  public checkCookie(cookieName: string){
    return this.cookieService.check(cookieName)
  }

  public setExpired(cookieName: string){
    const token = this.cookieService.get(cookieName);
    this.cookieService.set(cookieName, token, { expires: new Date(new Date().getTime() -  1000 * 60 * 60),path: '/'});
  }

  public saveRedirect(status: string){
    this.cookieService.set("redirect", status, {path: '/'});
  }

  public getRedirect(){
    return JSON.parse(this.cookieService.get("redirect"))
  }

}