import { EvidenceStatus } from "./evidence-status";

export class RequirementInfo {
    requirementId: string;
    evidencesStatus: EvidenceStatus[];
    public constructor(requirementId:string, evidencesStatus:EvidenceStatus[]) 
    {
        this.requirementId=requirementId; 
        this.evidencesStatus=evidencesStatus 
    }
}