import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

const OOTS_API_URL = environment.api_url + "/oots";

const headers = new HttpHeaders({
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": '*',
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT",
  "Access-Control-Max-Age": "300"
});

@Injectable({
  providedIn: 'root',
})
export class SessionTimerService {

  private isInitialized = false;
  private remainingTimeSubject = new BehaviorSubject<number>(0);
  remainingTime$: Observable<number> = this.remainingTimeSubject.asObservable();

  private uuid = new BehaviorSubject<string>('');
  uuid$: Observable<string> = this.uuid.asObservable();

  private sessionDataSubject = new BehaviorSubject<SessionData>(new SessionData());
  sessionData$: Observable<SessionData> = this.sessionDataSubject.asObservable();

  private timerExpired = new BehaviorSubject<boolean>(false);
  isTimerExpired$: Observable<boolean> = this.timerExpired.asObservable();

  constructor(private http: HttpClient) {
  }

  notifyTimerExpired(data: any) {
    this.timerExpired.next(data);
  }

  set requestUUID(uuid: string) {
    this.uuid.next(uuid);
  }

  get requestUUID(): string {
    return this.uuid.getValue();
  }

  get sessionData(): SessionData {
    return this.sessionDataSubject.getValue();
  }

  initializeTimer(sessionData: SessionData) {
    if(!this.isInitialized) {
      this.remainingTimeSubject.next(sessionData.time);
      this.sessionDataSubject.next(sessionData);
      const interval = setInterval(() => {
        const remainingTime = this.remainingTimeSubject.value;
        if (remainingTime > 15) {
          this.remainingTimeSubject.next(remainingTime - 1);
        } else {
          clearInterval(interval);
          this.notifyTimerExpired(true);
        }
      }, 1000);
      this.isInitialized = true;
    }
  }

  getSessionData(uuid: string): Observable<any> {
    const httpParams = new HttpParams().append("uuid", uuid);
    // headers
    const httpOptions = {
      headers: headers,
      params: httpParams
    };

    return this.http.get<any>(OOTS_API_URL + "/session", httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}, Message: ${error.message}`;
      console.log(errorMessage);
    }
    return throwError(() => {
      return error;
    });
  }

}

export class SessionData {
  uuid: string;
  time: number;
  returnUrl: string;
}