/* eslint-disable  @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataServiceDirectoryQueryParams } from '../_model/data-service-directory-query-params';

const DSD_API_URL = environment.api_url + "/dsd";

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
  "Access-Control-Allow-Origin": '*',
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT",
  "Access-Control-Max-Age": "300"
});

@Injectable({
  providedIn: 'root'
})
export class DataServiceDirectoryService {


  constructor(private http: HttpClient) { }

  search(params: DataServiceDirectoryQueryParams): Observable<any> {

    // query parameters
    let httpParams = new HttpParams();
    if (params.queryId != null) httpParams = httpParams.append("queryId", params.queryId);
    if (params.countryCode != null && params.countryCode != "null") httpParams = httpParams.append("countryCode", params.countryCode);
    if (params.evidenceTypeClassification != null && params.evidenceTypeClassification != "null") httpParams = httpParams.append("evidenceTypeClassification", params.evidenceTypeClassification);
    if (params.evidenceTypeId) httpParams = httpParams.append("evidenceTypeId", params.evidenceTypeId);
    if (params.jurisdictionAdminL2) httpParams = httpParams.append("jurisdictionAdminL2", params.jurisdictionAdminL2);
    if (params.jurisdictionAdminL3) httpParams = httpParams.append("jurisdictionAdminL3", params.jurisdictionAdminL3);
    if (params.jurisdictionContextId) httpParams = httpParams.append("jurisdictionContextId", params.jurisdictionContextId);

    // headers
    const httpOptions = {
      headers: headers,
      params: httpParams
    }

    console.log(httpParams);

    return this.http.get(DSD_API_URL + "/search", httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  getEvidenceProviders(params: DataServiceDirectoryQueryParams): Observable<any> {

    // query parameters
    let httpParams = new HttpParams();
    if (params.queryId != null) httpParams = httpParams.append("queryId", params.queryId);
    if (params.countryCode != null && params.countryCode != "null") httpParams = httpParams.append("countryCode", params.countryCode);
    if (params.evidenceTypeClassification != null && params.evidenceTypeClassification != "null") httpParams = httpParams.append("evidenceTypeClassification", params.evidenceTypeClassification);
    if (params.evidenceTypeId) httpParams = httpParams.append("evidenceTypeId", params.evidenceTypeId);
    if (params.jurisdictionAdminL2) httpParams = httpParams.append("jurisdictionAdminL2", params.jurisdictionAdminL2);
    if (params.jurisdictionAdminL3) httpParams = httpParams.append("jurisdictionAdminL3", params.jurisdictionAdminL3);
    if (params.jurisdictionContextId) httpParams = httpParams.append("jurisdictionContextId", params.jurisdictionContextId);

    // headers
    const httpOptions = {
      headers: headers,
      params: httpParams
    }

    console.log(httpParams);

    //MOCK
    if (environment.usingMocks) return this.http.get("mocks/evidenceProviders.json")

    return this.http.get(DSD_API_URL + "/providers", httpOptions)
      .pipe(
        catchError(this.handleError)
      )

  }

  fetchJurisdictionList(countryCode: string, jurisdictionLevel: string, code?: string): Observable<any> {
    const params: { countryCode: string; jurisdictionLevel: string; code?: string } = { countryCode, jurisdictionLevel };
    if (code) {
      params.code = code;
    }
    return this.http.get(DSD_API_URL + "/jurisdictionList", { params: params }).pipe(catchError(this.handleError));
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      console.log(errorMessage);
    }
    //window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

}
