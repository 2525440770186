import { Evidence } from "./evidence";

export class Requirement {
    number: number;
    id: string;
    title: string;
    status: string;
    evidences: Evidence[];
    haveProviders = false;
    selectedCountryCode: string;

    deleteEvidence(evidenceUUID: string): void {
        const evidenceToDelete = this.evidences.find(evidence => evidence.evidenceUUID === evidenceUUID);
        if (evidenceToDelete) {
            evidenceToDelete.status = "PENDING";
            evidenceToDelete.base64File = "";
            evidenceToDelete.mimeType = "";
            evidenceToDelete.fileName = "";
        }
    }
}
