import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SamlRequest } from '../_model/samlToken';
import { EidasUser } from '../_model/eidasUser';

const EIDAS_API_URL: string = environment.api_url + "/eidas";

@Injectable({
  providedIn: 'root'
})
export class EidasService {

  constructor(private http: HttpClient, private router:Router) { }

  getSamlRequest(isPreviewSpace: boolean): Observable<SamlRequest> {
    return this.http.get<SamlRequest>(EIDAS_API_URL, { params: { isPreviewSpace: isPreviewSpace } });
  }

  getFakeToken(): Observable<string> {
    return this.http.get(EIDAS_API_URL + "/fakeToken", { responseType: 'text'});
  }

  getFakeTokenWithRedirect(isPreviewSpace:boolean, uuid: string, session?:string): void{
    isPreviewSpace = isPreviewSpace== undefined ? false : isPreviewSpace;
    uuid = uuid== undefined ? '' : uuid;
    session = session== undefined ? '' : session;
    window.location.href = EIDAS_API_URL + "/fakeTokenWithRedirect?isPreviewSpace=" + isPreviewSpace + "&ootsUuid=" + uuid+ "&session=" + session;
  }

  checkUser(eidasUser:EidasUser, uuid: string): Observable<UserStatus>{
    return this.http.post<UserStatus>(EIDAS_API_URL + "/checkUser", { 'eidasUser': eidasUser , 'uuid': uuid});
  }

  isValidSession(session: string): Observable<boolean> {
    return this.http.get<boolean>(EIDAS_API_URL + "/validateSession", { params: { session: session } });
  }

  getRequestDataInfo(session: string) {
    return this.http.get<any>(EIDAS_API_URL + "/requestData", { params: { session: session } });
  }
}

export interface UserStatus {
  status: string;
}
