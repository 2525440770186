import { Component, OnInit } from '@angular/core';
import { JwtUtilsService } from '../_service/jwt-utils.service';
import { EidasUser } from '../_model/eidasUser';
import { OotsService } from '../_service/oots.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OotsRequest } from '../_model/oots-request';
import { SessionTimerComponent } from '../_common/session-timer/session-timer.component';
import { StateService } from '../_service/state.service';
import { DocumentCounterService } from '../_service/document-counter.service';
import { BreadcrumbService } from '../_service/breadcrumb.service';
import { Utils } from '../_common/utils';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  private eventsSubscription = new Subscription();
  continuationToken: string;
  uuid: string;
  title = 'IntermediaryApp - Home';
  isUserLogged = false;
  currentUser: EidasUser;
  ootsRequest: OotsRequest;
  tokenFound = true;
  isRequestDraftStatus = true;
  requirementsList: string[] = [];
  procedureDescription: string;
  step = 1;
  recievedDocuments: number;
  totalDocuments: number;
  isChecked = false;
  regex = /^[A-Za-z]{2}\/[A-Za-z]{2}\//;

  constructor(
    private jwtUtilService: JwtUtilsService,
    private ootsService: OotsService,
    private route: ActivatedRoute,
    private router: Router,
    private sessionTimerComponent: SessionTimerComponent,
    private stateService: StateService,
    private documentCounterService: DocumentCounterService,
    private breadcrumbService: BreadcrumbService,
  ) {
    this.eventsSubscription.add(
      this.documentCounterService.receivedDocumentsObserver.subscribe(receivedDocuments => this.recievedDocuments = receivedDocuments)
    );

    this.eventsSubscription.add(
      this.documentCounterService.totalDocumentsObserver.subscribe(totalDocuments => this.totalDocuments = totalDocuments)
    );

    this.eventsSubscription.add(
      this.breadcrumbService.getStep.subscribe(step => this.step = step)
    );

    this.eventsSubscription.add(
      this.stateService.ootsRequestModelObserver.subscribe(uuid => this.refreshOOTSRequestModel(uuid))
    );
  }

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;
    const uuid = queryParams['uuid'];
    if (uuid) {
      window.localStorage.setItem('uuid', uuid);
      this.uuid = uuid;
    } else {
      this.uuid = window.localStorage.getItem('uuid') ?? "";
    }
    this.jwtUtilService.getRedirect();
    console.log(this.jwtUtilService.getRedirect())
     if((window.performance.getEntries()[0] as PerformanceNavigationTiming).type  !== 'reload'){
      console.log(window.performance)
      const entry = window.performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming;
      console.log(window.performance.getEntriesByType("navigation"))
      console.log(entry.type)
      if(this.jwtUtilService.checkCookie('redirect')){
        console.log(this.jwtUtilService.getRedirect(), this.jwtUtilService.getRedirect() == true)
        if(this.jwtUtilService.getRedirect() == true){
          this.jwtUtilService.saveRedirect('false');
        }
        else{
          if(this.jwtUtilService.checkCookie('token')){this.jwtUtilService.setExpired('token')}
          if(this.jwtUtilService.checkCookie('oots-request-status')){this.jwtUtilService.setExpired('oots-request-status')}
          if(this.jwtUtilService.checkCookie('currentUser')){this.jwtUtilService.setExpired('currentUser')}
          if(this.jwtUtilService.checkCookie('errorType')){this.jwtUtilService.setExpired('errorType')}
          if(this.jwtUtilService.checkCookie('isPreview')){this.jwtUtilService.setExpired('isPreview')}
          window.localStorage.removeItem('uuid')
        }
      }
        
    }
    // Initialize timer
    this.sessionTimerComponent.initTimer(this.uuid);

    if (this.jwtUtilService.isLogged()) {
      this.isUserLogged = true;
      this.currentUser = this.jwtUtilService.getCurrentUser();
      console.log(this.currentUser.personIdentifier.split(this.regex))
      // check for a continuation token
      if (Utils.hasValue(this.stateService.continuationToken)) {
        this.continuationToken = this.stateService.continuationToken;
        this.stateService.clearContinuationToken();
        this.checkPreviousProcessWithToken();
      }
      if (this.uuid) this.fetchOOTSRequests(this.uuid);
    }
  }

  agreementChange(event: any) {
    this.stateService.agreementAccepted = this.isChecked;
  }

  continuationTokenChange(event: any) {
    this.stateService.continuationToken = this.continuationToken;
  }

  checkPreviousProcessWithToken() {
    const user = this.jwtUtilService.getCurrentUser();
    const userIdentifier = this.extractUserIdentifierFromEidasUser(user.personIdentifier);
    this.findPreviousRequestByToken(userIdentifier, this.continuationToken);
  }

  fetchOOTSRequests(uuid: string) {
    this.getOOTSRequest(uuid).subscribe(
      {
        next: result => {
          this.ootsRequest = JSON.parse(result);
          if (this.ootsRequest != null) {
            switch (this.ootsRequest.procedureCode) {
              case 'T1': this.procedureDescription = "Applying for a tertiary education study financing, such as study grants and loans from a public body or institution"; break;
              case 'T2': this.procedureDescription = "Submitting an initial application for admission to public tertiary education institution"; break;
              case 'T3': this.procedureDescription = "Requesting academic recognition of diplomas, certificates or other proof of studies or courses"; break;
              case 'V1': this.procedureDescription = "Registering a change of address"; break;
              case 'R1': this.procedureDescription = "Requesting proof of registration of birth"; break;
            }
            this.stateService.ootsRequest = this.ootsRequest;
            this.updateUserIdentifierIfNeeded(this.ootsRequest);
            this.ootsRequest.requirements.forEach(requirement => {
              this.requirementsList.push(requirement.title);
            });
          }
        },
        error: (e) => {
          console.error(e)
        },
      });
  }

  refreshOOTSRequestModel(uuid: string) {
    if(uuid) {
      this.getOOTSRequest(uuid).subscribe(
        {
          next: result => {
            this.ootsRequest = JSON.parse(result);
            if (this.ootsRequest != null) this.stateService.ootsRequest = this.ootsRequest;
          },
          error: (e) => {
            console.error(e)
          },
        });
    }
  }

  getOOTSRequest(uuid: string): Observable<any> {
    return this.ootsService.getOOTSRequestByUUID(uuid);
  }

  updateUserIdentifierIfNeeded(ootsRequest: OotsRequest) {
    // Update user identifier on out of session process
    if (!this.ootsRequest.inSession && this.ootsRequest.userIdentifier === null) {
      const user = this.jwtUtilService.getCurrentUser();
      ootsRequest.userIdentifier = this.extractUserIdentifierFromEidasUser(user.personIdentifier);
      this.ootsService.setUserIdentifier(ootsRequest).subscribe(
        {
          next: () => {
            this.stateService.ootsRequest = this.ootsRequest;
          },
          error: (e) => {
            console.error(e)
          }
        });
    }
  }

  findPreviousRequestByToken(userIdentifier: string, continuationToken: string) {
    this.ootsService.findPreviousRequestByToken(userIdentifier, continuationToken).subscribe(
      {
        next: result => {
          this.ootsRequest = JSON.parse(result);
          this.stateService.ootsRequest = this.ootsRequest;
        },
        error: (e) => {
          if (e.status == '404') this.tokenFound = false;
          if (e.status == '400') this.isRequestDraftStatus = false;
        }
      });
  }

  goToLink(link: string) {
    window.open(link, "_blank");
  }

  private extractUserIdentifierFromEidasUser(ediasUser: string): string {
    const lastIndex = ediasUser.lastIndexOf('/');
    if (lastIndex !== -1) return ediasUser.substring(lastIndex + 1);
    else return ediasUser;
  }

  onInputChange(event: any) {
    this.tokenFound = true;
  }

  toggle() {
    const container = document.querySelector('.expandable-container');
    container?.classList.toggle('contract');
    const lnk = document.querySelector('.toggle-lnk');
    lnk!.textContent = container?.classList.contains('contract') ? '▼ Expandir' : '▲ Contraer';
  }

}

