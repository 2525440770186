import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { JwtUtilsService } from '../_service/jwt-utils.service';

@Component({
  selector: 'app-login-maintenance',
  templateUrl: './login-maintenance.component.html',
  styleUrls: ['./login-maintenance.component.css']
})
export class LoginMaintenanceComponent {
  constructor(private http: HttpClient,private router: Router, private jwtUtilsService: JwtUtilsService){}
  showPassword=false;
  username:string;
  password:string;
  title:string;
  message:string;
  showDialog=false;

  submitLogin(){
    this.http.post(`${environment.api_url}/user/signin`, {"username":this.username, "password": this.password}).subscribe(
      response => {
        console.log(response);
        if(response!=undefined){
          this.router.navigate(['/management'])
        }
        else{
          this.showDialog = true;
          setTimeout(() => this.showDialog = false, 3000);
          this.title = "Alerta"
          this.message = "Credenciales erroneas."
        }        
      }
    );
  }
}
