<div class="container">

  <form [formGroup]="dsdForm" (ngSubmit)="onSubmit()">

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="queryId">COUNTRY</label>
          <select id="country" formControlName="countryCode">
            <option selected value="null">Not selected</option>
            <option *ngFor="let country of countryList" [value]="country.code">{{ country.name }}</option>
          </select>
        </div>
      </div>

      <div class="col-md-8">
        <div class="form-group">
          <label for="evidenceTypeClassification">EVIDENCE CLASSIFICATION TYPE</label>
          <input type="text" formControlName="evidenceTypeClassification" placeholder="Paste here the required evidence classification type obtained from evidence broker">
        </div>
      </div>
    </div>

    <app-loader-button (buttonClicked)="onSubmit()" text="SEND REQUEST"></app-loader-button>

    <div *ngIf="dsdForm.value">
      <label for="query">Query</label>
      <pre>{{ dsdForm.value | json }}</pre>
    </div>

    <div *ngIf="response">
      <label for="response">Response</label>
      <pre>{{ response | json }}</pre>
    </div>

  </form>

</div>