export const DEFAULT_DSD_QUERY_ID = 'urn:fdc:oots:dsd:ebxml-regrep:queries:dataservices-by-evidencetype-and-jurisdiction';

export const DSD_COUNTRIES = [
  { name: 'Finland', code: 'FI' },
  { name: 'Spain', code: 'ES' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Germany', code: 'DE' },
  { name: 'Romania', code: 'RO' },
];

export const DSD_EVIDENCE_TYPES_CLASSIFICATION = [
  'https://sr.oots.tech.europa.eu/evidencetypeclassifications/ES/ca8afed6-2dc0-422a-a931-d21c3d8daaaa',
  'https://sr.oots.tech.europa.eu/evidencetypeclassifications/DE/ca8afed6-2dc0-422a-a931-d21c3d8dcccc',
  'https://sr.oots.tech.europa.eu/evidencetypeclassifications/DE/ca8afed6-2dc0-422a-a931-d21c3d877777',
  'https://sr.oots.tech.europa.eu/evidencetypeclassifications/DE/ca8afed6-2dc0-422a-a931-d21c3d888888',
  'https://sr.oots.tech.europa.eu/evidencetypeclassifications/DE/ca8afed6-2dc0-422a-a931-d21c3d999999'
]