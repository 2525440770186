import { Evidence } from "./evidence";
import { Requirement } from "./requirement";

export class OotsRequest {
    uuid: string;
    userIdentifier: string
    procedureCode: string;
    inSession: boolean;
    evidences: Evidence[];
    requirements: Requirement[];
}
