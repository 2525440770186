import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { v4 as uuid } from 'uuid';
import { StateService } from '../_service/state.service';
import { JwtUtilsService } from '../_service/jwt-utils.service';
import { CookieService } from 'ngx-cookie-service';
import { ModalDialogService } from '../_service/modal-dialog.service';
import { ModalDialogComponent } from '../_common/modal-dialog/modal-dialog.component';

const OOTS_API_URL = environment.api_url + "/oots";
const TEST_API_URL = environment.api_url + "/test";

const headers = new HttpHeaders({
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": '*',
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT",
  "Access-Control-Max-Age": "300"
});
@Component({
  selector: 'app-sede',
  templateUrl: './sede.component.html',
  styleUrls: ['./sede.component.css']
})
export class SedeComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private stateService: StateService,
    private jwtUtilsService: JwtUtilsService,
    private cookieService: CookieService,
    private dialogService: ModalDialogService,) {
  }

  isResponse = false;
  returnUrl: string;
  specificDatasets: any[];
  selectedSpecificDataset: any;
  time = 10;

  ngOnInit(): void {
    this.clearPreviousData();
    this.stateService.clearRequestStatus();
    this.getSpecificDatasets();
    const queryParams = this.route.snapshot.queryParams;
    const retrieveResponse = queryParams['retrieveResponse'];
    const uuid = queryParams['uuid'];
    const isPostRedirect = queryParams['isPostRedirect'];
    const errorType = queryParams['errorType'];
    let data;
    if(errorType){
      if (errorType == 'invalidUrl') {
        this.cookieService.set("errorType", JSON.stringify("invalidUrl"), { path: '/' });
        data = {
          title: 'HOME.INCORRECT_URL_TITLE',
          message: 'HOME.INCORRECT_URL_TEXT',
          type: 'error-translate'
        };
      }
      if (errorType == 'petition') {
        this.cookieService.set("errorType", JSON.stringify("petition"), { path: '/' });
        data = {
          title: 'HOME.INCORRECT_PETITION_TITLE',
          message: 'HOME.INCORRECT_PETITION_TEXT',
          type: 'error-translate'
        };
      }
      this.stateService.respondSede = true;
      this.returnUrl = queryParams['returnUrl'];
      this.isResponse = true;
      this.receiveOOTSResponse("?uuid=" + uuid + "&errorType=" + errorType);
      this.shareErrorWithSede();
      this.dialogService.openErrorDialog(ModalDialogComponent, data);
    }
    
    if (retrieveResponse && uuid != undefined && !errorType) {
      this.receiveOOTSResponse("?uuid=" + uuid);
    }
    if (isPostRedirect && uuid != undefined) {
      this.getFromSede(uuid);
    }
  }

  getFromSede(uuid: string) {
    this.http.get<any>(TEST_API_URL + "/get-from-sede", { params: { uuid: uuid } }).subscribe(
      response => {
        if (response.success) this.ootsResponseXML = response.ootsResponseXML;
        else this.ootsResponseXML = "";
      }
    );
    this.isResponse = true;
  }

  receiveOOTSResponse(params: any) {
    this.http.post<any>(OOTS_API_URL + "/mock-response" + params, this.ootsResponseXML, { headers, responseType: 'text' as 'json'}).subscribe(
      response => {
        const result = JSON.parse(response);
        if (result.success) {
          this.ootsResponseXML = result.ootsResponseXML;
          this.returnUrl = result.returnUrl;
        }
        else this.ootsResponseXML = result.message;
      }
    );
    this.isResponse = true;
    this.ootsRequestXML = "";
  }

  redirectToIntermediaryApp() {
    if (this.selectedSpecificDataset) this.stateService.setPreview(JSON.stringify(this.selectedSpecificDataset.isPreview));
    else this.stateService.setPreview(JSON.stringify(true))
    this.jwtUtilsService.saveRedirect('true');
    this.postRedirect(OOTS_API_URL + "/request", this.ootsRequestXML);
  }

  postRedirect(path: string, xml: any): void {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = path;
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = 'data';
    hiddenField.value = xml;
    form.appendChild(hiddenField);
    document.body.appendChild(form);
    form.submit();
  }

  getDate(): string {
    const currentDate = Date.now();
    return this.datePipe.transform(currentDate, 'yyyy-MM-ddThh:mm:ss') ?? "";
  }

  shareWithSede() {
    if (this.returnUrl) this.postRedirect(this.returnUrl, this.ootsResponseXML);
  }

  getSpecificDatasets() {
    this.http.get<any>(environment.api_url + "/specificDataset/list").subscribe(
      response => {
        console.log(response)
        this.specificDatasets = response
        this.specificDatasets = this.specificDatasets.filter(dataset => !dataset.isHidden)

      }
    );
  }

  postRedirectSede(path: string, xml: string, returnUrl: string): void {
    const form = document.createElement('form');
        form.method = 'POST';
        form.action = path;
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = 'data';
        hiddenField.value = xml;
        form.appendChild(hiddenField);
        const hiddenFieldurl = document.createElement('input');
        hiddenFieldurl.type = 'hidden';
        hiddenFieldurl.name = 'url';
        hiddenFieldurl.value = returnUrl;
        form.appendChild(hiddenFieldurl);
        document.body.appendChild(form);
        form.submit();
  }

  shareErrorWithSede(){
    if(this.returnUrl){
      const interval = setInterval(() => {
        const remainingTime = this.time;
        if (remainingTime > 0) {
          this.time = this.time - 1
        } else {
          clearInterval(interval);
          this.stateService.respondSede = false;
          this.postRedirectSede(OOTS_API_URL + "/redirectSede", this.ootsResponseXML, this.returnUrl);
        }
      }, 1000);
    }
    
  }

  formatSelectedOotsRequest() {
    const request = this.selectedSpecificDataset.xml.replaceAll("XXXXXX", uuid()).replace("DDDDDD", this.getDate());
    const xmlDoc = new DOMParser().parseFromString(request, 'application/xml');
    const xsltDoc = new DOMParser().parseFromString([
      // describes how we want to modify the XML - indent everything
      '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
      '  <xsl:strip-space elements="*"/>',
      '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
      '    <xsl:value-of select="normalize-space(.)"/>',
      '  </xsl:template>',
      '  <xsl:template match="node()|@*">',
      '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
      '  </xsl:template>',
      '  <xsl:output indent="yes"/>',
      '</xsl:stylesheet>',
    ].join('\n'), 'application/xml');

    const xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(xsltDoc);
    const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
    const resultXml = new XMLSerializer().serializeToString(resultDoc);
    this.ootsRequestXML = resultXml;
  }

  clearPreviousData(){
    if(this.jwtUtilsService.checkCookie('token')){this.jwtUtilsService.setExpired('token')}
    if(this.jwtUtilsService.checkCookie('oots-request-status')){this.jwtUtilsService.setExpired('oots-request-status')}
    if(this.jwtUtilsService.checkCookie('currentUser')){this.jwtUtilsService.setExpired('currentUser')}
    if(this.jwtUtilsService.checkCookie('errorType')){this.jwtUtilsService.setExpired('errorType')}
    if(this.jwtUtilsService.checkCookie('isPreview')){this.jwtUtilsService.setExpired('isPreview')}
    window.localStorage.removeItem('uuid')
  }

  ootsRequestXML = `<OOTSPeticion xmlns="http://www.example.org/oots/">
  <IDPeticionBloque>
    <PeticionUUID>` + uuid() + `</PeticionUUID>
    <PeticionFechaHora>` + this.getDate() + `</PeticionFechaHora>
    <ProcedimientoCodigoDIR3>E05078801</ProcedimientoCodigoDIR3>
    <ProcedimientoCategoriaPDU>T2</ProcedimientoCategoriaPDU>
    <ProcedimientoCodigoSIA>2300114</ProcedimientoCodigoSIA>
    <NumReferenciaPostSolicitud>` + uuid() + `</NumReferenciaPostSolicitud>
    <DatosIdentidadUsuario>
      <IdentidadPersonaEidas>
        <Nombre>Nombre</Nombre>
        <Apellido>ApellidoPrimero ApellidoSegundo</Apellido>
        <FechaNacimiento>2000-01-01</FechaNacimiento>
        <IdentEidas>99999142H</IdentEidas>
        <NivelSeguridad>high</NivelSeguridad>
      </IdentidadPersonaEidas>
      <IdentidadPersonaNacional>
        <Identificador>99999142H</Identificador>
        <TipoIdentificador>NIF</TipoIdentificador>
        <Nombre>Nombre</Nombre>
        <Apellido1>ApellidoPrimero</Apellido1>
        <Apellido2>ApellidoSegundo</Apellido2>
        <TelefonoMovil>666555444</TelefonoMovil>
        <CorreoElectronico>email@sgad.es</CorreoElectronico>
      </IdentidadPersonaNacional>
    </DatosIdentidadUsuario>
  </IDPeticionBloque>
  <DatosRetornoBloque>
    <URLRetornoUsuario>https://oots.simplegob.com/intermediaryapp/test/share-with-sede</URLRetornoUsuario>
    <MinutosTimeOutSesion>50</MinutosTimeOutSesion>
  </DatosRetornoBloque>
  <RequisitosOOTSBloque>
    <IdRequisitoPruebasOOTS>https://sr.oots.tech.ec.europa.eu/requirements/f8a6a284-34e9-42c7-9733-63b5c4f4aa42</IdRequisitoPruebasOOTS>
    <TituloCondicionAAcreditar>Secondary School Diploma</TituloCondicionAAcreditar>
    <EsRequisitoObligatorio>0</EsRequisitoObligatorio>
    <ConTraduccionJurada>true</ConTraduccionJurada>
  </RequisitosOOTSBloque>
  <RequisitosOOTSBloque>
  <IdRequisitoPruebasOOTS>https://sr.oots.tech.ec.europa.eu/requirements/89d59b0e-3f1d-3069-b920-af58fd6a3fa0</IdRequisitoPruebasOOTS>
  <TituloCondicionAAcreditar>Proof of Birth PT</TituloCondicionAAcreditar>
  <EsRequisitoObligatorio>0</EsRequisitoObligatorio>
  <ConTraduccionJurada>true</ConTraduccionJurada>
</RequisitosOOTSBloque>
<RequisitosOOTSBloque>
  <IdRequisitoPruebasOOTS>https://sr.oots.tech.ec.europa.eu/requirements/00000000-0000-0000-0000-000000000000</IdRequisitoPruebasOOTS>
  <TituloCondicionAAcreditar>TEST PROCEDURE-000000000000 SE</TituloCondicionAAcreditar>
  <EsRequisitoObligatorio>0</EsRequisitoObligatorio>
  <ConTraduccionJurada>true</ConTraduccionJurada>
</RequisitosOOTSBloque>
<RequisitosOOTSBloque>
  <IdRequisitoPruebasOOTS>https://sr.oots.tech.ec.europa.eu/requirements/f7af4b42-3c0e-313e-a1c0-64de67a84bf3</IdRequisitoPruebasOOTS>
  <TituloCondicionAAcreditar>Proof of disability ES</TituloCondicionAAcreditar>
  <EsRequisitoObligatorio>0</EsRequisitoObligatorio>
  <ConTraduccionJurada>true</ConTraduccionJurada>
</RequisitosOOTSBloque>
<RequisitosOOTSBloque>
  <IdRequisitoPruebasOOTS>https://sr.oots.tech.ec.europa.eu/requirements/898bce19-e89c-36c7-8c3e-6c024a5277af</IdRequisitoPruebasOOTS>
  <TituloCondicionAAcreditar>Proof of nationality ES</TituloCondicionAAcreditar>
  <EsRequisitoObligatorio>0</EsRequisitoObligatorio>
  <ConTraduccionJurada>true</ConTraduccionJurada>
</RequisitosOOTSBloque>
<RequisitosOOTSBloque>
  <IdRequisitoPruebasOOTS>https://sr.oots.tech.ec.europa.eu/requirements/bf101a68-c2f9-3d97-977f-af364bf20ee4</IdRequisitoPruebasOOTS>
  <TituloCondicionAAcreditar>Proof of residence: domicile ES</TituloCondicionAAcreditar>
  <EsRequisitoObligatorio>0</EsRequisitoObligatorio>
  <ConTraduccionJurada>true</ConTraduccionJurada>
</RequisitosOOTSBloque>
<RequisitosOOTSBloque>
  <IdRequisitoPruebasOOTS>https://sr.oots.tech.ec.europa.eu/requirements/fa487ad2-ddec-3b15-82ac-3a510769a1c9</IdRequisitoPruebasOOTS>
  <TituloCondicionAAcreditar>Proof of enrolment in tertiary education institution ES</TituloCondicionAAcreditar>
  <EsRequisitoObligatorio>0</EsRequisitoObligatorio>
  <ConTraduccionJurada>true</ConTraduccionJurada>
</RequisitosOOTSBloque>
  </OOTSPeticion>`;

  ootsResponseXML = ``;
}

