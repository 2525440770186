export const QUERY_BY_PROCEDURE_AND_JURISDICTION = 'urn:fdc:oots:eb:ebxml-regrep:queries:requirements-by-procedure-and-jurisdiction';
export const QUERY_BY_REQUIREMENTS_AND_JURISDICTION = 'urn:fdc:oots:eb:ebxml-regrep:queries:evidence-types-by-requirement-and-jurisdiction';

export const EB_COUNTRIES = [
  { name: 'Finland', code: 'FI' },
  { name: 'Spain', code: 'ES' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Germany', code: 'DE' },
];

export const EB_PROCEDURE_IDS = [
  { name: 'Requesting a birth certificate', code: 'R1' },
  { name: 'Requesting proof of residence', code: 'S1' },
  { name: 'Applying for a tertiary education study financing, such as study grants and loans from a public body or institution', code: 'T1' },
  { name: 'Submitting an initial application for admission to public tertiary education institution', code: 'T2' }
];

/*
T3 Requesting academic recognition of diplomas, certificates or other proof of studies or courses
U1 Request for determination of applicable legislation in accordance with Title II of Regulation (EC) No 883/2004
T2 Submitting an initial application for admission to public tertiary education institution
U2 Notifying changes in the personal or professional circumstances of the person receiving social security benefits, relevant for such benefits
U3 Application for a European Health Insurance Card (EHIC)
U4 Submitting an income tax declaration
V1 Registering a change of address
V2 Registering a motor vehicle originating from or already registered in a Member State, in standard procedures
V3 Obtaining stickers for the use of the national road infrastructure: time-based charges (vignette), distance-based charges (toll), issued by a public body or institution
V4 Obtaining emission stickers issued by a public body or institution
W1 Claiming pension and pre-retirement benefits from compulsory schemes
X1 Notification of business activity, permission for exercising a business activity, changes of business activity and the termination of a business activity not involving insolvency or liquidation procedures, excluding the initial registration of a business activity with the business register and excluding procedures concerning the constitution of or any subsequent filing by companies or firms within the meaning of the second paragraph of Article 54 TFEU
X2 Registration of an employer (a natural person) with compulsory pension and insurance schemes
X3 Registration of employees with compulsory pension and insurance schemes
X4 Submitting a corporate tax declaration
X5 Notification to the social security schemes of the end of contract with an employee, excluding procedures for the collective termination of employee contracts
X6 Payment of social contributions for employees
*/
