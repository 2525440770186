import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Action } from 'src/app/_model/action-enum';
import { OotsRequest } from 'src/app/_model/oots-request';
import { BreadcrumbService } from 'src/app/_service/breadcrumb.service';
import { JwtUtilsService } from 'src/app/_service/jwt-utils.service';
import { OotsService } from 'src/app/_service/oots.service';
import { StateService } from 'src/app/_service/state.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnDestroy {

  private eventsSubscription = new Subscription();
  step: number;
  ootsRequest: OotsRequest;
  isOotsPreview: boolean;

  constructor(
    private router: Router,
    private stateService: StateService,
    private breadcrumbService: BreadcrumbService,
    private jwtUtilService: JwtUtilsService,
    private ootsService: OotsService
  ) {
    this.eventsSubscription.add(this.breadcrumbService.getStep.subscribe(step => this.step = step));
    this.eventsSubscription.add(this.stateService.ootsRequestObserver.subscribe(ootsRequest => this.ootsRequest = ootsRequest));
    this.eventsSubscription.add(this.stateService.ootsPreviewObserver.subscribe(isOotsPreview => this.isOotsPreview = isOotsPreview));
  }

  showTimer() { return this.isHeaderVisible(); }
  showBreadcrum() { return this.isHeaderVisible(); }
  showTimerAndButtons() { return this.isUserLogged() && this.isHeaderVisible(); }

  save() {
    this.stateService.action = Action.SAVE;
  }

  cancel() {
    this.stateService.action = Action.CANCEL;
  }

  oostRequestActive() {
    return this.stateService.ootsRequest != null && this.stateService.ootsRequest.uuid != null;
  }

  oostPreview() {
    return this.isOotsPreview == true;
  }

  isHeaderVisible() {
    if (this.router.url.includes('/sede')
      || this.router.url.includes('/registry')) return false;
    return true;
  }

  isUserLogged() {
    return this.jwtUtilService.isLogged();
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }

  initializeComponent(step: number) {
    this.stateService.ootsRequestModelChanged(this.ootsRequest.uuid);
    this.breadcrumbService.setStep(step);
  }
}
