<div class="container">
  <form [formGroup]="ebForm">

    <!--
    <div class="row">
      <div class="col-md-7">
        <div class="form-group">
          <label for="queryId">QUERY ID</label>
          <select id="queryId" formControlName="queryId" *ngIf="queryIdList.length === 1" (change)="onChange()">
            <option *ngFor="let queryId of queryIdList" [value]="queryId">{{ queryId }}</option>
          </select>
        </div>
      </div>
    </div>
      -->

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="queryId">COUNTRY</label>
          <select id="country" formControlName="countryCode" (change)="onCountryChange()">
            <option selected value="null">Not selected</option>
            <option *ngFor="let country of countryList" [value]="country.code">{{ country.name }}</option>
          </select>
        </div>
      </div>

      <!--
      <div class="col-md-8">
        <div class="form-group">
          <label for="procedureId">PROCEDURE ID</label>
          <select id="procedureId" formControlName="procedureId" (change)="onChange()">
            <option selected value="null">Not selected</option>
            <option *ngFor="let procedure of procedureIdList" [value]="procedure.code">{{ procedure.name }}</option>
          </select>
        </div>
      </div>
        -->

        <div class="col-md-6" *ngIf="requirementIds">
          <div class="form-group">
            <label for="requirementId">REQUIREMENT ID</label>
            <select id="requirementId" formControlName="requirementId" (change)="onRequirementChange()">
              <option selected value="null">Not selected</option>
              <option *ngFor="let requirement of requirementIds" [value]="requirement.identifier">{{ requirement.name }}
              </option>
            </select>
          </div>
        </div>

    </div>

    <div *ngIf="ebForm.value">
      <label for="query">Query</label>
      <pre>{{ ebForm.value | json }}</pre>
    </div>

    <div *ngIf="response">
      <label for="response">Response</label>
      <pre>{{ response | json }}</pre>
    </div>

  </form>

</div>