import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { StateService } from '../_service/state.service';
import { JwtUtilsService } from '../_service/jwt-utils.service';
import { EidasUser } from '../_model/eidasUser';
import { Utils } from '../_common/utils';
import { MultiLanguageStringPipe } from '../_model/multi-language-string';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { QueryRequestData } from '../_model/queryRequestData';
import { AccessPointService } from '../_service/access-point.service';
import { BreadcrumbService } from '../_service/breadcrumb.service';
import { LoaderButtonComponent } from '../_common/loader-button/loader-button.component';
import { COUNTRIES, CountryAsset } from '../contants';
import { TranslateService } from '@ngx-translate/core';
import { ModalDialogService } from '../_service/modal-dialog.service';
import { ModalDialogComponent } from '../_common/modal-dialog/modal-dialog.component';

declare const require: any;
const i18nIsoCountries = require("i18n-iso-countries");
@Component({
  selector: 'app-locate-evidence-preview',
  templateUrl: './locate-evidence-preview.component.html',
  styleUrls: ['./locate-evidence-preview.component.css']
})
export class LocateEvidencePreviewComponent implements OnInit{
  @Input() requestInfo: QueryRequestData;
  @Input() session: string;
  @Input() returnUrl: string;

  @ViewChild(LoaderButtonComponent) sendButton: LoaderButtonComponent;

  isUserLogged: boolean;
  currentUser: EidasUser;
  continuationToken: string;
  communityList: Community[];
  pidInfo: PidInfo;
  countryAsset: CountryAsset;
  document: string;
  step: number;

  //TODO: manage community on page refresh and PID.
  community: string;

  regex = /^[A-Za-z]{2}\/[A-Za-z]{2}\//;

  constructor(
    private http: HttpClient,
    private stateService: StateService,
    private jwtUtilService: JwtUtilsService,
    private multiLanguagePipe: MultiLanguageStringPipe,
    private apService: AccessPointService,
    private breadcrumbService: BreadcrumbService,
    public translate: TranslateService,
    private dialogService: ModalDialogService,
  ) {}

  ngOnInit(): void{
    console.log(this.requestInfo)
    this.stateService.ootsPreview = true;
    if (this.jwtUtilService.isLogged()) {
      this.isUserLogged = true;
      this.currentUser = this.jwtUtilService.getCurrentUser();
      console.log(this.currentUser.personIdentifier.split(this.regex))
      // check for a continuation token
      if (Utils.hasValue(this.stateService.continuationToken)) {
        this.continuationToken = this.stateService.continuationToken;
        this.stateService.clearContinuationToken();
      }
      this.getPIDRequirement();
      this.getCommunities();
      this.countryAsset = COUNTRIES[this.requestInfo.evidenceRequester.address.adminUnitLevel1.value]
      this.breadcrumbService.getStep.subscribe(step => this.step = step);

    }
  }

  getCommunities() {
    this.http.get<any>(environment.api_url + "/community/list",  { params: { selected: true} }).subscribe(
      response => {
        this.communityList = response
      }
    );
  }

  //Gets requirement informatión for request to PID 
  getPIDRequirement(){
    this.apService.getPIDInfo(this.requestInfo.requirements[0].identifier.value).subscribe(
      response => {
        this.pidInfo = response;
      }
    );
  }

  private extractUserIdentifierFromEidasUser(ediasUser: string): string {
    const lastIndex = ediasUser.lastIndexOf('/');
    if (lastIndex !== -1) return ediasUser.substring(lastIndex + 1);
    else return ediasUser;
  }

  transformTexts(collection:any[], lang: string){
    i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/es.json"));
    const translatedStr = this.multiLanguagePipe.transform(collection, lang) ?? "";
    return translatedStr;
  }

  //This mocks the document input from pid to get to further steps, not a final method
  searchDocumentMock(){
    this.apService.obtainEvidence(this.session).subscribe(data => {
      console.log(data);
      if(data){
        this.document = data;
        this.updateStep(2);
        this.sendButton.requestFinished();
      }
      else{
        this.sendButton.requestFinished();
      }
    });
  }

  updateStep(newStep: number) {
    this.breadcrumbService.setStep(newStep)
  }

  //OOTSResponse
  sendEvidence(accepted: boolean){
    this.apService.sendResponse({ peticion: this.session, accepted: accepted }).subscribe(
      {
        next: result => {
          console.log(result);
        },
        error: (e) => {
          console.error(e)
        },
        complete: () => {
          console.log("completed")
        }
      }
    );
    this.navigateBack();
  }

  openEvidenceSend() {
    this.updateStep(4);
    const data = {
      message: this.translate.instant("PREVIEW_SPACE.SENDING_PROOF"),
      type: 'preview-send'
    }
    this.dialogService.openDialog(ModalDialogComponent, data)
      .subscribe(result => {
        if (result?.action === 'accept') {
          this.sendEvidence(true);
        }
        else if(result?.action === 'cancel'){
          this.updateStep(3);
        }
      });
  }

  openCancelSend() {
    this.updateStep(4);
    const data = {
      message: this.translate.instant("PREVIEW_SPACE.CANCEL_SUBMISSION_MESSAGE"),
      type: 'preview-send'
    }
    this.dialogService.openDialog(ModalDialogComponent, data)
      .subscribe(result => {
        if (result?.action === 'accept') {
          this.sendEvidence(false);
        }
        else if(result?.action === 'cancel'){
          this.updateStep(3);
        }
      });
  }

  dialogErrorOpen(){
    const data = {
      message: this.translate.instant("PREVIEW_SPACE.PROOF_NOT_FOUND"),
      type: 'error-preview'
    }
    this.dialogService.openDialog(ModalDialogComponent, data)
      .subscribe(result => {
        if (result?.action === 'accept') {
          this.navigateBack();
        }
      });
  }

  dialogErrorRegionOpen(){
    const data = {
      message: this.translate.instant("PREVIEW_SPACE.PROOF_NOT_FOUND_REGION"),
      type: 'error-preview-region'
    }
    this.dialogService.openDialog(ModalDialogComponent, data)
      .subscribe(result => {
        if (result?.action === 'accept') {
          this.sendEvidence(true);
        }
        else if(result?.action === 'cancel'){
          this.navigateBack();
        }
      });
  }

  //Return to requesters portal
  navigateBack() {
    window.location.href = this.returnUrl;
  }

  //Open proof inn other tab
  viewProof(){
    const byteArray = new Uint8Array(atob(this.document).split('').map(char => char.charCodeAt(0)));
    console.log(this.document)
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url, "_blank");
  }

}

export class Community{
  id: string;
  name: string;
}

export class PidInfo{
  id: string;
  pidEvidenceId: string;
  description: string;
  national: boolean;
}
