/* eslint-disable  @typescript-eslint/no-explicit-any */

import { Component, OnInit, ViewChild } from '@angular/core';
import { DataServiceDirectoryService } from '../_service/data-service-directory.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DEFAULT_DSD_QUERY_ID, DSD_COUNTRIES, DSD_EVIDENCE_TYPES_CLASSIFICATION } from './constants';
import { LoaderButtonComponent } from '../_common/loader-button/loader-button.component';
import { EvidenceBrokerService } from '../_service/evidence-broker.service';
import { DEFAULT_LANGUAGE } from '../contants';

@Component({
  selector: 'app-data-service-directory',
  templateUrl: './data-service-directory.component.html',
  styleUrls: ['./data-service-directory.component.css']
})
export class DataServiceDirectoryComponent implements OnInit {

  public request: any;
  public response: any;
  dsdForm: FormGroup;
  formBuilder: FormBuilder;
  queryIdList = [DEFAULT_DSD_QUERY_ID];
  countryList = DSD_COUNTRIES;
  evidenceTypeClassificationList = DSD_EVIDENCE_TYPES_CLASSIFICATION;

  @ViewChild(LoaderButtonComponent) sendButton: LoaderButtonComponent;

  constructor(private ebService: EvidenceBrokerService, private dsdService: DataServiceDirectoryService) { }

  ngOnInit(): void {
    this.dsdForm = new FormGroup({
      queryId: new FormControl(DEFAULT_DSD_QUERY_ID),
      countryCode: new FormControl(),
      evidenceTypeClassification: new FormControl()
    });

    this.fetchEvidenceCountries();
  }

  fetchEvidenceCountries() {
    this.ebService.getEvidenceCountries(DEFAULT_LANGUAGE).subscribe(
      {
        next: result => {
          this.countryList = result.evidenceCountries;
          console.info(result);
        },
        error: (e) => console.error(e),
        complete: () => console.info('complete')
      });
  }

  onSubmit() {
    this.doSearch(this.dsdForm.value);
   }

  doSearch(queryParams: any): void {
    this.dsdService.search(queryParams).subscribe(
      {
        next: result => {
          this.response = result;
          console.info(result);
          this.sendButton.requestFinished();
        },
        error: (e) => {
          this.response = e;
          console.error(e)
          this.sendButton.requestFinished();
        },
        complete: () => {
          console.info('complete')
        }
      });
  }

}
