import { Component } from '@angular/core';
import { SessionData, SessionTimerService } from 'src/app/_service/session-timer.service';

@Component({
  selector: 'app-session-timer',
  templateUrl: './session-timer.component.html',
  styleUrls: ['./session-timer.component.css']
})
export class SessionTimerComponent {
  
  remainingTime = this.sessionTimerService.remainingTime$ ?? "expired";
  constructor(private sessionTimerService: SessionTimerService) {
  }

  initTimer(uuid: string): void {
    this.sessionTimerService.getSessionData(uuid).subscribe(
      {
        next: response => {
          const sessionData = new SessionData();
          sessionData.returnUrl = response.returnUrl;
          sessionData.time = response.time;
          sessionData.uuid = response.uuid;
          this.sessionTimerService.initializeTimer(sessionData);
        },
        error: (e) => {
          console.error(e);
        }
      });
  }
}