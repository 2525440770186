<div class="d-flex justify-content-end">
    <button (click)="return()"><i class="bi bi-x-lg"></i></button>
</div>
<div>
    <h1 class="lin-tit">{{'FOOTER.LEGAL_WARNING' | translate}}</h1>
    <p>{{'LEGAL_WARNING.TEXT1' | translate}}</p>
    <p>{{'LEGAL_WARNING.TEXT2' | translate}}</p>
    <p>{{'LEGAL_WARNING.TEXT3' | translate}}</p>
    <p>{{'LEGAL_WARNING.TEXT4' | translate}}</p>
    <h3 class="lin-sub">{{'LEGAL_WARNING.SUBTITLE1' | translate}}</h3>
    <p>{{'LEGAL_WARNING.TEXT5' | translate}}</p>
    <p>{{'LEGAL_WARNING.TEXT6' | translate}}</p>
    <p>{{'LEGAL_WARNING.TEXT7' | translate}}</p>
    <p>{{'LEGAL_WARNING.TEXT8' | translate}}</p>
    <p>{{'LEGAL_WARNING.TEXT9' | translate}}</p>
</div>
