<div class="container">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="SEND (EXPERT MODE)" class="mat-tab">
      <ng-template matTabContent>
        <form (ngSubmit)="onSubmit()">

          <div class="formfield">
            <label for="xmlContent">XML REQUEST</label>
            <textarea class="xmlContent" [(ngModel)]="xmlContent" name="xmlContent"></textarea>
          </div>

          <div><app-loader-button (buttonClicked)="onSubmit()" text="SEND REQUEST"></app-loader-button></div>

          <div class="row">
            <div class="col-md-12">
                <div *ngIf="response">
                  <label for="response">XML RESPONSE</label>
                  <pre>{{ response }}</pre>
                </div>
              </div>
          </div>

        </form>
      </ng-template>
    </mat-tab>

    <mat-tab label="SEND (GUIDED MODE)">
      <ng-template matTabContent>

        <form [formGroup]="apForm" (ngSubmit)="onGuidedSubmit()">

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">REQUESTER ID</label>
                <input type="text" class="form-control" required formControlName="evidenceRequesterId">
              </div>
              <div class="form-group">
                <label for="name">PROVIDER ID</label>
                <input type="text" class="form-control" required formControlName="evidenceProviderId">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">REQUIREMENT ID</label>
                <input type="text" class="form-control" required formControlName="evidenceRequirementId">
              </div>
              <div class="form-group">
                <label for="name">COUNTRY CODE</label>
                <input type="text" class="form-control" required formControlName="countryCode">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="formfield">
                <label for="payload">PAYLOAD</label>
                <textarea class="payloadContent" formControlName="payload" name="payload"></textarea>
              </div>
              <div>
                <app-loader-button (buttonClicked)="onGuidedSubmit()" text="SEND REQUEST"></app-loader-button>
                <!--
                <div class="error-message"
                  *ngIf="formSubmitted && (apForm.get('evidenceProviderId')?.value === '' || apForm.get('evidenceRequesterId')?.value === '')">
                  Missing fields.
                </div>
                  -->
              </div>


            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
                <div *ngIf="guidedResponse">
                  <label for="guidedResponse">RESPONSE</label>
                  <pre>{{ guidedResponse | json }}</pre>
                </div>
              </div>
          </div>
        </form>
      </ng-template>
    </mat-tab>

    <mat-tab label="RETRIEVE" class="mat-tab">

      <ng-template matTabContent>

        <form>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="queryId">PENDING MESSAGES</label>
                <select id="pendingMessages" [(ngModel)]="selectedMessageId" name="pendingMessages">
                  <option selected value="null">Not selected</option>
                  <option *ngFor="let message of pendingMessagesList" [value]="message">{{ message }}</option>
                </select>
              </div>
            </div>
          </div>

          <app-loader-button (buttonClicked)="retrieveMessage()" text="RETRIEVE"></app-loader-button>

          <div class="row">
            <div class="col-md-12">
              <div *ngIf="messageRetrieved">
                <label for="retrievedMessage">MESSAGE</label>
                <pre style="max-height: 550px;">{{ messageRetrieved }}</pre>
              </div>
            </div>
          </div>

        </form>
      </ng-template>
    </mat-tab>

  </mat-tab-group>

</div>