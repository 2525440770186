import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentCounterService {

  private documentsTotal = 0;
  private documentsReceived = 0;

  private totalDocumentsSubject = new BehaviorSubject(0);
  public totalDocumentsObserver = this.totalDocumentsSubject.asObservable();

  private receivedDocumentsSubject = new BehaviorSubject(0);
  public receivedDocumentsObserver = this.receivedDocumentsSubject.asObservable();

  get totalDocuments(): number {
    return this.documentsTotal;
  }

  get receivedDocuments(): number {
    return this.documentsReceived;
  }

  addDocumentTotal(): void {
    this.documentsTotal++;
    this.totalDocumentsSubject.next(this.documentsTotal);
  }

  addDocumentsTotal(numDocuments: number): void {
    this.documentsTotal = this.documentsTotal + numDocuments;
    this.totalDocumentsSubject.next(this.documentsTotal);
  }

  resetDocumentsTotal(): void {
    this.documentsTotal = 0;
    this.totalDocumentsSubject.next(this.documentsTotal);
  }

  removeDocumentTotal(): void {
    if (this.documentsTotal > 0) {
      this.documentsTotal--;
      this.totalDocumentsSubject.next(this.documentsTotal);
    }
  }

  addDocumentReceived(): void {
    this.documentsReceived++;
    this.receivedDocumentsSubject.next(this.documentsReceived);
  }

  addDocumentsReceived(numDocuments: number): void {
    this.receivedDocumentsSubject.next(numDocuments);
  }
  
  removeDocumentReceived(): void {
    if (this.documentsReceived > 0) {
      this.documentsReceived--;
      this.receivedDocumentsSubject.next(this.documentsReceived);
    }
  }

}
