import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

const MANAGEMENT_API_URL = environment.api_url + "/management";

const headers = new HttpHeaders({
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": '*',
  "Access-Control-Allow-Headers": "Origin, Content-Type, Content-Length, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT",
});

@Injectable({
  providedIn: 'root'
})
export class ManagementService {

  constructor(private http: HttpClient){}

  saveWhitelistEntry(whitelistEntry: any){
    return this.http.post(MANAGEMENT_API_URL + "/whitelist/save", whitelistEntry, { headers, responseType:'text'})
      .pipe(
        catchError(this.handleError)
      )
  }
  getWhitelist(limit: any): Observable<any> {
    return this.http.post<any>(MANAGEMENT_API_URL + "/whitelist/list", limit, { headers })
      .pipe(
        catchError(this.handleError)
      )
  }
  deleteWhitelistEntry(id: number){
    return this.http.get(MANAGEMENT_API_URL + "/whitelist/delete?id=" + id, { headers, responseType:'text'})
      .pipe(
        catchError(this.handleError)
      )
  }
  
  getWhitelistLength(): Observable<any> {
    return this.http.get<any>(MANAGEMENT_API_URL + "/whitelist/length", {headers})
      .pipe(
        catchError(this.handleError)
      )
  }

  getLoggedUser(): Observable<any>{
    return this.http.get(`${environment.api_url}/user/loggedUser`, {headers})
      .pipe(
        catchError(this.handleError)
      )
  }

  logoutUser(): Observable<any>{
    return this.http.get(`${environment.api_url}/user/logout`, { headers, responseType: 'text' as 'json' })
      .pipe(
        catchError(this.handleError)
      )
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}, Message: ${error.message}`;
      console.log(errorMessage);
    }
    console.log(errorMessage);
    //window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  changeScheduler(milis: number){
    return this.http.get(MANAGEMENT_API_URL + "/changeScheduler?milis=" + milis, { headers, responseType:'text'})
      .pipe(
        catchError(this.handleError)
      )
  }

  getPropertiesLength(): Observable<any> {
    return this.http.get<any>(MANAGEMENT_API_URL + "/properties/length", {headers})
      .pipe(
        catchError(this.handleError)
      )
  }

  getProperties(limit: any): Observable<any> {
    return this.http.post<any>(MANAGEMENT_API_URL + "/properties/list", limit, { headers })
      .pipe(
        catchError(this.handleError)
      )
  }
  savePropertiesEntry(property: any){
    return this.http.post(MANAGEMENT_API_URL + "/properties/save", property, { headers, responseType:'text'})
      .pipe(
        catchError(this.handleError)
      )
  }
}
