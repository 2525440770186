import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'multiLanguage'
})
export class MultiLanguageStringPipe implements PipeTransform {

    private readonly defaultLanguage = "en";

    transform(value: MultiLanguageString[], lang: string): string | undefined {
        if(!(value instanceof Array)) return value;
        if (!value || value.length === 0) return undefined;
        if (!value || value.length === 1) return value[0].value;
        lang = lang ? lang.toLowerCase() : '';
        if (!lang || !value.some(item => item.lang.toLowerCase() === lang)) lang = this.defaultLanguage;
        const selectedString = value.find(item => item.lang.toLowerCase() === lang);
        return selectedString ? selectedString.value : undefined;
    }
}

export class MultiLanguageString {
    value: string;
    lang: string;
}