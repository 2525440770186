<div class="bluebox">
    <span class="title" >{{'ENTRY.HEADER' | translate}}</span>
    
</div>
<div class="title-image">
    <img class="image" src="assets/documents-image.jpg" alt="">
</div>

<div class="procedures">
    <div>
        <h3>{{'ENTRY.SUBTITLE' | translate}}</h3>
    </div>
    <div *ngFor="let dataset of specificDatasets">
        <div class="dataset">
            <div>{{dataset.type}} - {{translateText('ENTRY', dataset.type)}}</div>
            <div><app-loader-button (buttonClicked)="redirectToIntermediaryApp(dataset)"
                [text]="translateText('ENTRY', 'SELECT')"></app-loader-button></div>
        </div>
    </div>
</div>
