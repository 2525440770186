import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ManagementService } from '../_service/management.service';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { JwtUtilsService } from '../_service/jwt-utils.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatPaginatorModule, CommonModule, FormsModule],
})
export class ManagementComponent implements AfterViewInit{
  displayedColumns: string[] = ['id', 'url', 'active', 'actions'];
  columnsProperties: string[] = ['propertyName', 'value', 'actions'];
  loggedUser:any = undefined;
  dataSource: MatTableDataSource<any>;
  dataSourceProperties: MatTableDataSource<any>;
  myDate: Date = new Date();
  totalElements = 0;
  totalProperties = 0;
  limits = [0,5];
  propertiesLimits = [0,5]
  baseData: any[] = [];
  propertyData: any[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  scheduler: 0;
  propertyValue: "";

  visibleInputs = false;
  visibleInputsProperties = false;
  urlInput:string;
  isActive = true;

  constructor(private managementService: ManagementService, private router: Router, private jwtUtilsService: JwtUtilsService){}

  ngAfterViewInit() {
    this.search();
    this.searchProperties();
    this.managementService.getLoggedUser().subscribe({
      next: result => {
        this.loggedUser = result;
      }
    })
  }

  search() {
    this.managementService.getWhitelistLength().subscribe(
      {
         next: result => {
          this.totalElements = result;
         }
      }
    )
    this.managementService.getWhitelist(this.limits).subscribe(
      {
         next: result => {
          this.baseData = result;
          this.dataSource = new MatTableDataSource(result);
         }
      }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  nextPage(event: PageEvent) {
    this.limits[0] = event.pageIndex * event.pageSize;
    this.limits[1] = event.pageSize;
    this.search();
  }

  nextPageProperties(event: PageEvent) {
    this.propertiesLimits[0] = event.pageIndex * event.pageSize;
    this.propertiesLimits[1] = event.pageSize;
    this.searchProperties();
  }

  delete(id:number){
    this.managementService.deleteWhitelistEntry(id).subscribe({next: response => {
      this.search()
    }})
  }

  saveEdit(id: number){
    const whitelistEntry =
    {
      "id": id,
      "url": this.urlInput,
      "active": this.isActive 
    }
    this.managementService.saveWhitelistEntry(whitelistEntry).subscribe({next: response => {
      this.visibleInputs = false;
      this.search();
    }})
  }

  save(){
    const whitelistEntry =
    {
      "url": this.urlInput,
      "active": this.isActive 
    }
    this.managementService.saveWhitelistEntry(whitelistEntry).subscribe({next: response => {
      this.visibleInputs = false;
      this.limits[0] = 0;
      this.search();
    }})
  }

  setSaving(){
    const math = Math;
    this.visibleInputs = true;
    this.managementService.getWhitelistLength().subscribe(
      {
         next: result => {
          let limit = math.trunc(result/this.limits[1]);
          limit ++;
          this.limits[0] = limit * this.limits[1];
          this.managementService.getWhitelist(this.limits).subscribe(
            {
               next: result => {
                this.baseData = result;
                this.dataSource = new MatTableDataSource(result);
                const extendedData = this.baseData;
                extendedData.push({id: "", url: "", active:false, actions: "new"});
                this.dataSource = new MatTableDataSource(extendedData);
               }
            }
          )
         }
      }
    )
  }
  cancelSave(){
    this.dataSource = new MatTableDataSource(this.baseData);
    this.visibleInputs = false;
    this.limits[0] = 0;
    this.search();
  }
  
  changeScheduler(){
    this.managementService.changeScheduler(this.scheduler).subscribe({next: response => {
      this.visibleInputs = false;
      this.limits[0] = 0;
    }})
  }
  searchProperties() {
    this.managementService.getPropertiesLength().subscribe(
      {
         next: result => {
          this.totalProperties = result;
         }
      }
    )
    this.managementService.getProperties(this.propertiesLimits).subscribe(
      {
         next: result => {
          this.propertyData = result;
          this.dataSourceProperties = new MatTableDataSource(result);
         }
      }
    )
  }
  saveEditedProperty(propertyName: string){
    const property =
    {
      "propertyName": propertyName,
      "value": this.propertyValue,
    }
    this.managementService.savePropertiesEntry(property).subscribe({next: response => {
      this.visibleInputsProperties = false;
      this.searchProperties();
    }})
  }
  logout(){
    this.managementService.logoutUser().subscribe({next: response => {
        this.router.navigate(['/login-maintenance']);
      },
      error: (err) =>{
        this.router.navigate(['/login-maintenance'])
      }
    }
    
    )
  }
}
