<div class="bluebox">
    <span class="title" >Inicio de sesión
    </span>
</div>
<div class="general-container">
    <div class="form">
        <div class="form-field">
            <input type="text" placeholder="Username" class="input" [(ngModel)]="username">
        </div>
        <div class="form-field">
            <div class="password-input input">
                <input class="pass-input" type="{{showPassword ? 'text' : 'password' }}" name="password"
                  placeholder= 'Password' [(ngModel)]="password">
                  <button class="eye-pass" (click)="showPassword=!showPassword"><i class="fa-solid" [ngClass]="{'fa-eye-slash': !showPassword, 'fa-eye': showPassword}"></i></button>
              </div>
        </div>
    </div>
    <button class="login-but" (click)="submitLogin()">Login</button>
</div>

<div class="custom-alert" *ngIf="showDialog">
    <p class="title-alert">{{title}}</p>
    <p class="message-alert">{{message}}</p>
</div>
