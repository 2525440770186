/* eslint-disable  @typescript-eslint/no-explicit-any */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EvidenceBrokerQueryParams } from '../_model/evidence-broker-query-params';

const EB_API_URL = environment.api_url + "/eb";

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
  "Access-Control-Allow-Origin": '*',
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT",
  "Access-Control-Max-Age": "300"
});

@Injectable({
  providedIn: 'root'
})
export class EvidenceBrokerService {

  constructor(private http: HttpClient) { }

  search(params: EvidenceBrokerQueryParams): Observable<any> {

    // query parameters
    let httpParams = new HttpParams();
    if (params.queryId != null) httpParams = httpParams.append("queryId", params.queryId);
    if (params.countryCode != null && params.countryCode != "null") httpParams = httpParams.append("countryCode", params.countryCode);
    if (params.jurisdictionAdminL2) httpParams = httpParams.append("jurisdictionAdminL2", params.jurisdictionAdminL2);
    if (params.jurisdictionAdminL3) httpParams = httpParams.append("jurisdictionAdminL3", params.jurisdictionAdminL3);
    if (params.procedureId && params.procedureId != "null") httpParams = httpParams.append("procedureId", params.procedureId);
    if (params.requirementId) httpParams = httpParams.append("requirementId", params.requirementId);

    // headers
    const httpOptions = {
      headers: headers,
      params: httpParams
    }

    // MOCK
    if (environment.usingMocks) return this.http.get('mocks/requirements.json');

    return this.http.get(EB_API_URL + "/search", httpOptions)
      .pipe(
        catchError(this.handleError)
      )

  }

  fetchEvidenceCountriesByRequirementId(requirementId: string, language?: string): Observable<any> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append("requirementId", requirementId);
    if(language) httpParams = httpParams.append("language", language);

    // headers
    const httpOptions = {
      headers: headers,
      params: httpParams
    }

    return this.http.get(EB_API_URL + "/countries", httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  getEvidenceClassification(params: EvidenceBrokerQueryParams): Observable<any> {

    // query parameters
    let httpParams = new HttpParams();
    if (params.queryId != null) httpParams = httpParams.append("queryId", params.queryId);
    if (params.countryCode != null && params.countryCode != "null") httpParams = httpParams.append("countryCode", params.countryCode);
    if (params.requirementId) httpParams = httpParams.append("requirementId", params.requirementId);

    // headers
    const httpOptions = {
      headers: headers,
      params: httpParams
    }

    // MOCK
    if (environment.usingMocks) return this.http.get("mocks/classificationType.json");

    return this.http.get(EB_API_URL + "/classification", httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  listRequirementIds(procedureId: string, language: string): Observable<any> {

    // query parameters
    const httpParams = new HttpParams().append("procedureId", procedureId).append("displayLanguage", language);

    // headers
    const httpOptions = {
      headers: headers,
      params: httpParams
    }

    console.log(httpParams);

    return this.http.get(EB_API_URL + "/requirements", httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  getEvidenceCountries(language: string): Observable<any> {

    // query parameters
    const httpParams = new HttpParams().append("language", language);

    // headers
    const httpOptions = {
      headers: headers,
      params: httpParams
    }

    return this.http.get(EB_API_URL + "/countries", httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}, Message: ${error.message}`;
      console.log(errorMessage);
    }
    return throwError(() => {
      return errorMessage;
    });
  }

}
