export const DEFAULT_LANGUAGE = 'EN';
export const EU_FLAG = 'assets/country-flags/eu.png';
export const ES_FLAG = 'assets/country-flags/es.png';
export const AT_FLAG = 'assets/country-flags/at.webp';
export const BE_FLAG = 'assets/country-flags/be.webp';
export const CZ_FLAG = 'assets/country-flags/cz.webp';
export const DE_FLAG = 'assets/country-flags/de.webp';
export const FI_FLAG = 'assets/country-flags/fi.webp';
export const FR_FLAG = 'assets/country-flags/fr.webp';
export const GR_FLAG = 'assets/country-flags/gr.webp';
export const HU_FLAG = 'assets/country-flags/hu.webp';
export const IE_FLAG = 'assets/country-flags/ie.webp';
export const IT_FLAG = 'assets/country-flags/it.webp';
export const LU_FLAG = 'assets/country-flags/lu.webp';
export const MT_FLAG = 'assets/country-flags/mt.webp';
export const PL_FLAG = 'assets/country-flags/pl.webp';
export const SE_FLAG = 'assets/country-flags/se.webp';
export const SI_FLAG = 'assets/country-flags/si.webp';
export const SK_FLAG = 'assets/country-flags/sk.webp';
export const NL_FLAG = 'assets/country-flags/nl.webp';
export const CY_FLAG = 'assets/country-flags/cy.webp';
export const DK_FLAG = 'assets/country-flags/dk.webp';
export const EE_FLAG = 'assets/country-flags/ee.webp';
export const HR_FLAG = 'assets/country-flags/hr.webp';
export const LT_FLAG = 'assets/country-flags/lt.webp';
export const LV_FLAG = 'assets/country-flags/lv.webp';
export const PT_FLAG = 'assets/country-flags/pt.webp';
export const RO_FLAG = 'assets/country-flags/ro.webp';

export const COUNTRIES: { [key: string]: CountryAsset } = {
    ES: { name: 'Spain', flag: ES_FLAG },
    AT: { name: 'Austria', flag: AT_FLAG },
    BE: { name: 'Belgium', flag: BE_FLAG },
    CZ: { name: 'Czech Republic', flag: CZ_FLAG },
    DE: { name: 'Germany', flag: DE_FLAG },
    FI: { name: 'Finland', flag: FI_FLAG },
    FR: { name: 'France', flag: FR_FLAG },
    EL: { name: 'Greece', flag: GR_FLAG },
    HU: { name: 'Hungary', flag: HU_FLAG },
    IE: { name: 'Ireland', flag: IE_FLAG },
    IT: { name: 'Italy', flag: IT_FLAG },
    LU: { name: 'Luxembourg', flag: LU_FLAG },
    MT: { name: 'Malta', flag: MT_FLAG },
    PL: { name: 'Poland', flag: PL_FLAG },
    SE: { name: 'Sweden', flag: SE_FLAG },
    SI: { name: 'Slovenia', flag: SI_FLAG },
    SK: { name: 'Slovakia', flag: SK_FLAG },
    EU: { name: 'European Union', flag: EU_FLAG },
    NL: { name: 'Netherlands', flag: NL_FLAG },
    CY: { name: 'Netherlands', flag: CY_FLAG },
    DK: { name: 'Netherlands', flag: DK_FLAG },
    EE: { name: 'Netherlands', flag: EE_FLAG },
    HR: { name: 'Netherlands', flag: HR_FLAG },
    LT: { name: 'Netherlands', flag: LT_FLAG },
    LV: { name: 'Netherlands', flag: LV_FLAG },
    PT: { name: 'Netherlands', flag: PT_FLAG },
    RO: { name: 'Netherlands', flag: RO_FLAG }
};
export interface CountryAsset {
    name: string;
    flag: string;
}

export const nutsLevels = [
    { key: '1', value: 'NUTS1' },
    { key: '2', value: 'NUTS2' },
    { key: '3', value: 'NUTS3' },
    { key: 'LAU', value: 'LAU' }
];

export function getNextNutsLevel(currentKey: string): { key: string, value: string } | null {
    const currentIndex = nutsLevels.findIndex(item => item.key === currentKey);

    if (currentIndex !== -1 && currentIndex < nutsLevels.length - 1) {
        return nutsLevels[currentIndex + 1];
    } else {
        return null; // If the current key is not found or it's the last item in the array
    }
}
