import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-politica-cookies',
  templateUrl: './politica-cookies.component.html',
  styleUrls: ['./politica-cookies.component.css']
})
export class PoliticaCookiesComponent {
  constructor(private location: Location) {}
  return(){
    this.location.back();
  }
}
