<div class="container">

    <div class="row">
        <div class="col-md-11">
            <div class="title">{{'DISCLAIMER.AGREEMENT' | translate}}</div>
        </div>
    </div>
    <div class="row mt-3">
        <!--div class="col-md-11">SGAD (Secretaría General de Administración Digital) is committed to protecting your
            privacy and personal data. We only collect and process your data for the purposes of providing you with our
            services.<br> We do not sell or share your data with any third parties without your consent. You have the right
            to access, rectify, erase, restrict, and object to the processing of your data, as well as to withdraw your
            consent at any time.<br> For more information, please read our privacy policy.</div-->
        <div class="col-md-11">{{'DISCLAIMER.AGREEMENT_DESC' | translate}}</div>
        <div class="col-md-11">{{'DISCLAIMER.AGREEMENT_DESC_1' | translate}}</div>
        <div class="col-md-11">{{'DISCLAIMER.AGREEMENT_DESC_2' | translate}}</div>
    </div>
    <div class="row">
        <div class="col d-flex align-items-center">
            <p><input type="checkbox" (change)="allowFurther($event)" [(ngModel)]="isChecked"></p>
            <p class="">{{'DISCLAIMER.I_HAVE_READ' | translate }}</p>
        </div>
    </div>
    <div class="row button-row justify-content-end">
        <div class="col-md-6 r-5"><button id="acceptButton" disabled (click)="onAccept()">{{'HOME.CONTINUE_EIDAS' | translate}}</button></div>
    </div>
</div>