<div>

    <div class="divider"></div>
    <div *ngIf="isUserLogged; then userIdentified else userNotIdentified"></div>
    <ng-template #userNotIdentified class="col-md-6">
        <div style="position: relative; margin-bottom: 200px; padding-top: 1rem;">
            <div class="bluebox" style="background-color:  rgba(17, 77, 166, 1); padding: 2rem 2rem; ">
                <span class="title" style="color: white;padding-left: 50px;">{{'HOME.GATEWAY_FULL' |
                    translate}}</span>
                <p class="title" style="color: white; font-size: small; padding-left: 50px;">
                    {{'HOME.GATEWAY_FULL_DESC' |
                    translate}}</p>
            </div>
            <div style="position: absolute; margin-top: -50px;">
                <table>
                    <tr>
                        <td>
                            <div class="bluebox"
                                style="background-color:  white; padding: 5rem; padding-top: 3rem; margin-left: 5rem; padding-right: 5rem; text-align: justify; margin-top: -20px; ">
                                <p>{{'HOME.COPY_OF_DOCUMENTS' | translate}}
                                </p>
                                <p>
                                </p>
                            </div>
                        </td>
                        <td style="width: 375px;">
                            <img height="225; padding-right: 140px;" alt="" src="assets/dintel_resource.png" />
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row mt-3"
            style="background-color: #F7F7F8; position: relative; padding-left: 6em; padding-right: 6em; margin-top: -20px;">
            <h1>{{'DISCLAIMER.AGREEMENT' | translate}}</h1>

            <div class="col-md-11" style="margin-bottom: 5px;">{{'DISCLAIMER.AGREEMENT_DESC' | translate}}</div>
            <div class="col-md-11" style="margin-bottom: 5px;">{{'DISCLAIMER.AGREEMENT_DESC_1' | translate}}</div>
            <div class="col-md-11" style="margin-bottom: 10px;">{{'DISCLAIMER.AGREEMENT_DESC_2' | translate}}</div>
        </div>

        <div class="row" style="background-color: #F7F7F8; position: relative; padding-left: 6em; padding-right: 6em;">
            <div class="col d-flex align-items-center">
                <p><input type="checkbox" [(ngModel)]="isChecked" (change)="agreementChange($event)"></p>
                <p class="fw-bold">{{'DISCLAIMER.I_HAVE_READ' | translate}}</p>
            </div>
        </div>
        <div class="row evidence-info" *ngIf="requestInfo !== undefined">
            <p class="p-0 m-0 subtitle">{{'PREVIEW_SPACE.REQUEST_DATA' | translate}}</p>
            <ul>
                <li>{{'PREVIEW_SPACE.COMPETENT_AUTHORITY' | translate}}
                    <ul>
                        <li>{{'PREVIEW_SPACE.NAME' | translate}}: {{transformTexts(requestInfo.evidenceRequester.name, 'EN')}}</li>
                        <li>{{'PREVIEW_SPACE.COUNTRY' | translate}}: {{changeCountryLanguage(requestInfo.evidenceRequester.address.adminUnitLevel1.value ,'EN')}}</li>
                    </ul>
                </li>
            </ul>
            <ul>
                <li>{{'PREVIEW_SPACE.PROCEDURE' | translate}}
                    <ul>
                        <li>{{'PREVIEW_SPACE.NAME' | translate}}: {{requestInfo.requirements[0].name.value}}</li>
                        <li>{{'PREVIEW_SPACE.TYPE' | translate}}: {{transformTexts(requestInfo.procedure.localizedString, "EN")}}</li>
                    </ul>
                </li>
            </ul>
            <ul>
                <li>{{'PREVIEW_SPACE.REQUESTED_PROOF' | translate}}
                    <ul>
                        <li>{{transformTexts(requestInfo.evidenceRequest.description ,"EN")}}</li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="row button-row justify-content-end">
            <section *ngIf="!isValidSession" class="eidas-signin">
                <div class="subtitle">{{'PREVIEW_SPACE.SIGN_IN' | translate}}</div>
                <p>{{'PREVIEW_SPACE.SIGN_IN_DESC' | translate}}
                </p>
                <div class="eidas-login">
                    <app-eidas-component [isPreviewSpace]=true [session]="session"></app-eidas-component>
                    <app-eidas-callback [isPreviewSpace]=true [session]="session"></app-eidas-callback>
                </div>
            </section>
            <section style="background-color: #E8EFFA; margin: 0px; padding: 0px; position: relative;">
                <div style="background-color: #E8EFFA; padding: 0px !important;">
                    <table style="margin-top:50px; width:100%; text-align: center; width: 100%; height: 5em; ">
                        <tr>
                            <td>
                                <a style=" background-color: white; padding:20px;text-decoration:underline; color:cornflowerblue;" href="#"
                                    tabindex="0">{{'HOME.LEARN_MORE_EID'
                                    | translate}} &rarr;</a>
                            </td>
                            <td>
                                <a style="background-color: white; padding:20px; text-decoration:underline; color:cornflowerblue;" href="#"
                                    (click)="goToLink('https://ec.europa.eu/digital-building-blocks/sites/display/DIGITAL/Once+Only+Technical+System')" tabindex="0">{{'HOME.LEARN_MORE_OOTS'
                                    | translate}} &#8594;</a>
                            </td>
                        </tr>
                    </table>
                </div>
            </section>
        </div>
        
        <section *ngIf="isUserLogged" class="user-profile">
            <div class="user-label"><i class="fa-regular fa-user"></i></div>
            <div class="user-info">
                <h2>{{ currentUser.firstName }}</h2>
                <p>{{ currentUser.familyName }} &nbsp; - &nbsp; {{ currentUser.personIdentifier }}</p>
            </div>
        </section>
    </ng-template>

    <ng-template #userIdentified>
        <div *ngIf="isValidSession then validSession else invalidSession"></div>

        <ng-template #validSession>
            <!-- <section class="evidence-accepter" *ngIf="!experimentalPreviewSpace">
                <h2>{{'PREVIEW_SPACE.DO_YOU_ACCEPT' | translate}}</h2>
                <div class="w-50 d-flex justify-content-between my-3">
                    <button class="button" (click)="acceptOrDenyEvidence(true)">{{'PREVIEW_SPACE.ACCEPT' |
                        translate}}</button>
                    <button class="button btn-danger" (click)="acceptOrDenyEvidence(false)">{{'PREVIEW_SPACE.REJECT' |
                        translate}}</button>
                </div>
                <ngx-extended-pdf-viewer class="pdf-viewer" [base64Src]="pdfLink">
                </ngx-extended-pdf-viewer>
            </section> -->
            <section>
                <app-locate-evidence-preview *ngIf="requestInfo" [requestInfo]="requestInfo" [session]="session" [returnUrl]="returnUrl"></app-locate-evidence-preview>
            </section>
        </ng-template>

        <ng-template #invalidSession>
            <p>{{'PREVIEW_SPACE.SESSION_NOT_FOUND' | translate}}<b>{{session}}</b></p>
            <div>
                <button class="button" (click)="navigateBack()">{{'PREVIEW_SPACE.RETURN' | translate}}</button>
            </div>
        </ng-template>
    </ng-template>
</div>