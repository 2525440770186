<div class="">

    <div id="notfound" *ngIf="!requestFound" class="row request-not-found hidden">
        <div class="col-md-12 mt-2 pt-2">
            <p><i class="fa-solid fa-circle-exclamation"></i><span
                    style="vertical-align: text-bottom;">{{'LOCATE.NOT_FOUND' | translate}}</span></p>
        </div>
    </div>
    <div *ngIf="requestFound">
        <div *ngIf="step === 2 || step === 3" class="row provider-found">
            <div class="col-md-12 mt-2 pt-2">
                <table>
                    <tr>
                        <td><i class="fa fa-info-circle icon-background fa-lg" aria-hidden="true"></i></td>
                        <td><b>{{'EV_REQUESTED.DOC_LOCATED' | translate}}</b></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <p>{{'EV_REQUESTED.DOC_LOCATED_DESC' | translate}}</p>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="evidences">
            <!--  <p class="subtitle">Select the country that issued your document(s)</p>-->
            <div class="row" *ngFor="let requirement of ootsRequest.requirements">
                <div class="col-md-12">
                    <app-requirement ootsRequestId="{{ ootsRequest.uuid }}" [requirement]="requirement"
                        (countrySelected)="countryListener($event)"
                        (evidenceListener)="evidenceListener($event);"></app-requirement>
                </div>
            </div>
        </div>
        <section class="footer-count">
            <div class="row">
                <div class="d-flex justify-content-center">
                    <button class="mt-4" *ngIf="step === 2" [disabled]="!isStep3Available"
                        (click)="locateDocuments()">{{ 'LOCATE.FIND_SELECTED_DOCS' | translate
                        }}</button>
                    <button class="mt-4" *ngIf="step === 3" (click)="updateStep(4)" [disabled]="!isStep4Available()">{{
                        'HEADER.FINISH_PROCESS' | translate
                        }}</button>
                    <div *ngIf="step===4">
                        <div *ngIf="ootsRequest.inSession">
                            <button class="mt-4" (click)="finishSession()">{{ 'LOCATE.RETURN_TO_REQUEST' | translate
                                }}</button>
                        </div>
                        <div *ngIf="!ootsRequest.inSession">
                            <button class="mt-4" (click)="saveOrShareOutOfSession('SAVE')">{{ 'LOCATE.SAVE_AND_RETURN' |
                                translate }}</button>

                            <!-- <button class="mt-4" (click)="saveOrShareOutOfSession('FINISH')">{{ 'LOCATE.SHARE_DOCS' |
                                translate }}</button> -->

                        </div>
                    </div>
                    <div *ngIf="requestFound" style="display: flex; justify-content: flex-end;">
                        <button class="mt-4" (click)="mockSedeResponse()">{{'HOME.MOCK_RESPONSE' | translate }}</button>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <button id="show-preview-dialog" (click)="openPreviewDialog()" style="display: none;">subscribe</button>
    <button id="show-timeout-dialog" (click)="openTimeoutDialog()" style="display: none;">timeout</button>
    <button id="show-error-dialog" (click)="openErrorDialog()" style="display: none;">error</button>
    <button id="show-contact-dialog" (click)="openContactDialog()" style="display: none;">contact</button>
    <button id="show-secCode-dialog" (click)="openSecurityCodeDialog()" style="display: none;">secCode</button>
    <button id="show-cancel-dialog" (click)="openCancelProcessDialog()" style="display: none;">cancel</button>
</div>