<div class="d-flex justify-content-end">
    <button (click)="return()"><i class="bi bi-x-lg"></i></button>
</div>
<div>
    <h1 class="lin-tit">{{'PRIVACY_POLICY.TITLE1' | translate}}</h1>
    <p>{{'PRIVACY_POLICY.TEXT1' | translate}}</p>

    <h3 class="lin-sub">{{'PRIVACY_POLICY.TITLE2' | translate}}</h3>
    <p>{{'PRIVACY_POLICY.TEXT2' | translate}}</p>
    <p>{{'PRIVACY_POLICY.TEXT3' | translate}}</p>
    <p>{{'PRIVACY_POLICY.TEXT4' | translate}}</p>
    <p>{{'PRIVACY_POLICY.TEXT5' | translate}}</p>
    <ul>
        <li>{{'PRIVACY_POLICY.LISTEL1' | translate}}</li>
        <li>{{'PRIVACY_POLICY.LISTEL2' | translate}}</li>
    </ul>

    <h3 class="lin-sub">{{'PRIVACY_POLICY.TITLE3' | translate}}</h3>
    <p>{{'PRIVACY_POLICY.TEXT6' | translate}}</p>
    <ul>
        <li>{{'PRIVACY_POLICY.LISTEL3' | translate}}</li>
        <li>{{'PRIVACY_POLICY.LISTEL4' | translate}}</li>
    </ul>

    <h3 class="lin-sub">{{'PRIVACY_POLICY.TITLE4' | translate}}</h3>
    <p>{{'PRIVACY_POLICY.TEXT9' | translate}}</p>
    <p>{{'PRIVACY_POLICY.TEXT10' | translate}}</p>
    <ul>
        <li>{{'PRIVACY_POLICY.LISTEL8' | translate}}</li>
        <li>{{'PRIVACY_POLICY.LISTEL9' | translate}}</li>
    </ul>

    <h3 class="lin-sub">{{'PRIVACY_POLICY.TITLE5' | translate}}</h3>
    <p>{{'PRIVACY_POLICY.TEXT11' | translate}}</p>

    <h3 class="lin-sub">{{'PRIVACY_POLICY.TITLE6' | translate}}</h3>
    <p>{{'PRIVACY_POLICY.TEXT12' | translate}}</p>
    <p>{{'PRIVACY_POLICY.TEXT13' | translate}}</p>
    <p>{{'PRIVACY_POLICY.TEXT14' | translate}}</p>
    <ul>
        <li>{{'PRIVACY_POLICY.LISTEL10' | translate}}</li>
        <li>{{'PRIVACY_POLICY.LISTEL11' | translate}}</li>
        <li>{{'PRIVACY_POLICY.LISTEL12' | translate}}</li>
        <li>{{'PRIVACY_POLICY.LISTEL13' | translate}}</li>
        <li>{{'PRIVACY_POLICY.LISTEL14' | translate}}</li>
        <li>{{'PRIVACY_POLICY.LISTEL15' | translate}}</li>
        <li>{{'PRIVACY_POLICY.LISTEL16' | translate}}</li>
        <li>{{'PRIVACY_POLICY.LISTEL17' | translate}}</li>
        <li>{{'PRIVACY_POLICY.LISTEL18' | translate}}</li>
    </ul>

    <h3 class="lin-sub">{{'PRIVACY_POLICY.TITLE8' | translate}}</h3>
    <p>{{'PRIVACY_POLICY.TEXT15' | translate}}</p>
    <p>{{'PRIVACY_POLICY.TEXT16' | translate}}</p>
    <ul>
        <li>{{'PRIVACY_POLICY.LISTEL19' | translate}}</li>
        <li>{{'PRIVACY_POLICY.LISTEL20' | translate}}</li>
        <li>{{'PRIVACY_POLICY.LISTEL21' | translate}}</li>
    </ul>

    <h3 class="lin-sub">{{'PRIVACY_POLICY.TITLE9' | translate}}</h3>
    <p>{{'PRIVACY_POLICY.TEXT17' | translate}}</p>
    <ul>
        <li>{{'PRIVACY_POLICY.LISTEL22' | translate}}</li>
        <li>{{'PRIVACY_POLICY.LISTEL23' | translate}}</li>
        <li>{{'PRIVACY_POLICY.LISTEL24' | translate}}</li>
    </ul>

    <h3 class="lin-sub">{{'PRIVACY_POLICY.TITLE10' | translate}}</h3>
    <p>{{'PRIVACY_POLICY.TEXT18' | translate}}</p>
    <p>{{'PRIVACY_POLICY.TEXT19' | translate}}</p>
    <p>{{'PRIVACY_POLICY.TEXT20' | translate}}</p>

    <h3 class="lin-sub">{{'PRIVACY_POLICY.TITLE11' | translate}}</h3>
    <p>{{'PRIVACY_POLICY.TEXT21' | translate}}</p>
    <p>{{'PRIVACY_POLICY.TEXT22' | translate}}</p>
    <p>{{'PRIVACY_POLICY.TEXT23' | translate}}</p>
    <p>{{'PRIVACY_POLICY.TEXT24' | translate}}</p>
	<p>{{'PRIVACY_POLICY.TEXT25' | translate}}</p>

    <h3 class="lin-sub">{{'PRIVACY_POLICY.TITLE12' | translate}}</h3>
	<p>{{'PRIVACY_POLICY.TEXT26' | translate}}</p>
    <p>{{'PRIVACY_POLICY.TEXT27' | translate}}</p>
    <p>{{'PRIVACY_POLICY.TEXT28' | translate}}</p>
    <p>{{'PRIVACY_POLICY.TEXT29' | translate}}</p>
	<h3 class="lin-sub">{{'PRIVACY_POLICY.TITLE13' | translate}}</h3>
	<p>{{'PRIVACY_POLICY.TEXT30' | translate}}</p>
</div>

