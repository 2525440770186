import { Component } from '@angular/core';
import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.css']
})
export class AgreementComponent {


  constructor(
    public dialogRef: DialogRef, 
    public translate: TranslateService) {
   }

  isChecked:boolean;

  allowFurther($event: any) {
    const acceptButton =document.getElementById("acceptButton");
    if(this.isChecked){
      acceptButton?.removeAttribute("disabled");
    }else{
      acceptButton?.setAttribute("disabled","true");
    }
}
  onAccept() {
    this.dialogRef.close('accept');
  }
  onCancel() {
    throw new Error('Method not implemented.');
  }

}
