import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PidRequest } from '../_model/pid-request';

const PREVIEW_API_URL = environment.api_url + "/preview";

const headers = new HttpHeaders({
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": '*',
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT",
  "Access-Control-Max-Age": "300"
});

@Injectable({
  providedIn: 'root'
})
export class PreviewService {

  constructor(private http: HttpClient) { }

  getEvidence(pidRequest: PidRequest): Observable<any> {
    return this.http.post<any>(PREVIEW_API_URL + "/evidence/pdf", pidRequest, { headers, responseType: 'text' as 'json' })
      .pipe(
        catchError(this.handleError)
      )
  }

  getServicesInformation(requirementId: string): Observable<any> {
    const httpParams = new HttpParams().append("requirementId", requirementId);
    const httpOptions = { headers: headers, params: httpParams };
    return this.http.get<any>(PREVIEW_API_URL + "/services", httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  returnError(session: string): Observable<any>{
    return this.http.post<any>(PREVIEW_API_URL + "/error", session)
      .pipe(
        catchError(this.handleError)
      )
  }
  
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}, Message: ${error.message}`;
      console.log(errorMessage);
    }
    return throwError(() => {
      return error;
    });
  }
}
