import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timer',
})
export class SessionTimerPipe implements PipeTransform {
  transform(seconds: number | null): string {

    if (seconds == null) {
      return 'expired';
    }

    const minutes: number = Math.floor(seconds / 60);
    const remainingSeconds: number = seconds % 60;
    const formattedMinutes: string = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds: string = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

    return `${formattedMinutes}:${formattedSeconds}`;
  }
}
