import { Component, Input, OnInit } from '@angular/core';
import { EidasService } from '../../_service/eidas-service.service';
import { SamlRequest } from '../../_model/samlToken';
import { StateService } from 'src/app/_service/state.service';
import { CookieService } from 'ngx-cookie-service';
import { JwtUtilsService } from 'src/app/_service/jwt-utils.service';

@Component({
  selector: 'app-eidas-component',
  templateUrl: './eidas-component.component.html',
  styleUrls: ['./eidas-component.component.css']
})
export class EidasComponent implements OnInit {

  @Input() isPreviewSpace: boolean;
  @Input() uuid: string;
  @Input() session?: string;

  isChecked: boolean;
  petitionError = false;

  constructor(
    private eidasService: EidasService,
    private stateService: StateService,
    private cookieService: CookieService,
    private jwtUtilsService: JwtUtilsService) {
    this.stateService.agreementObserver.subscribe(isChecked => this.isChecked = isChecked);
  }

  ngOnInit(): void {
    console.log("EidasComponent initialized");
    this.errorManage();
  }

  redirectToEidas(): void {
    console.log("Redirecting to eIDAS");
    this.jwtUtilsService.saveRedirect('true');
    this.eidasService.getSamlRequest(this.isPreviewSpace).subscribe({
      next: (data: SamlRequest) => {
        this.postToEidas(data.nodeService, { 'SAMLRequest': data.SamlRequest, 'RelayState': data.RelayState });
      }
    })
  }

  postToEidas(path: string, params: any): void {
    const form = document.createElement('form');
    form.method = 'post';
    form.action = path;

    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];
        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  }

  errorManage() {
    if (this.cookieService.check('errorType')) {
      const errorType = JSON.parse(this.cookieService.get("errorType"));
      if (errorType == 'petition') this.petitionError = true;
    }
  }

  byPassEidas() {
    this.jwtUtilsService.saveRedirect('true');
    this.eidasService.getFakeTokenWithRedirect(this.isPreviewSpace, this.uuid, this.session)
  }
}
