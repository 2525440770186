/* eslint-disable  @typescript-eslint/no-explicit-any */

import { Component, OnInit } from '@angular/core';
import { EvidenceBrokerService } from '../_service/evidence-broker.service'
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { QUERY_BY_PROCEDURE_AND_JURISDICTION, EB_COUNTRIES, EB_PROCEDURE_IDS, QUERY_BY_REQUIREMENTS_AND_JURISDICTION } from './constants';
import { DEFAULT_LANGUAGE } from '../contants';

@Component({
  selector: 'app-evidence-broker',
  templateUrl: './evidence-broker.component.html',
  styleUrls: ['./evidence-broker.component.css']
})
export class EvidenceBrokerComponent implements OnInit {

  public request: any;
  public response: any;
  public requirementIds: any[];
  ebForm: FormGroup;
  formBuilder: FormBuilder;
  queryIdList = [QUERY_BY_PROCEDURE_AND_JURISDICTION];
  countryList = EB_COUNTRIES;
  procedureIdList = EB_PROCEDURE_IDS;

  constructor(private ebService: EvidenceBrokerService) { }

  ngOnInit(): void {
    this.ebForm = new FormGroup({
      queryId: new FormControl(QUERY_BY_PROCEDURE_AND_JURISDICTION, Validators.required),
      countryCode: new FormControl(),
      procedureId: new FormControl(),
      requirementId: new FormControl()
    });

    this.fetchEvidenceCountries();

    const queryParams = { "queryId": QUERY_BY_PROCEDURE_AND_JURISDICTION };
    this.doSearch(queryParams);
  }

  onRequirementChange() {
    if (this.ebForm.valid) {
      this.doSearch(this.ebForm.value);
    }
  }

  onCountryChange() {
    this.requirementIds = [];
    this.ebForm.get("requirementId")?.setValue(null);
    if (this.ebForm.valid) {
      this.doSearch(this.ebForm.value);
    }
  }

  fetchEvidenceCountries() {
    this.ebService.getEvidenceCountries(DEFAULT_LANGUAGE).subscribe(
      {
        next: result => {
          this.countryList = result.evidenceCountries;
          console.info(result);
        },
        error: (e) => console.error(e),
        complete: () => console.info('complete')
      });
  }

  doSearch(queryParams: any): void {

    let isEvidenceTypeQuery = false;

    if(queryParams.requirementId != null && queryParams.requirementId != undefined) {
      queryParams.queryId = QUERY_BY_REQUIREMENTS_AND_JURISDICTION;
      isEvidenceTypeQuery = true;
    }
    this.ebService.search(queryParams).subscribe(
      {
        next: result => {
          this.response = result;
          if(!isEvidenceTypeQuery) this.requirementIds = result.requirementTypes;
          console.info(result);
        },
        error: (e) => {
          this.response = e;
          console.error(e)
        },
        complete: () => console.info('complete')
      });
  }

  listRequirementIds(procedureId: string): void {
    this.ebService.listRequirementIds(procedureId, DEFAULT_LANGUAGE).subscribe(
      {
        next: result => {
          this.requirementIds = result.requirementTypes;
          console.info(result);
        },
        error: (e) => console.error(e),
        complete: () => console.info('complete')
      });
  }
}

