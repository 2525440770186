import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EidasUser } from '../_model/eidasUser';
import { JwtUtilsService } from '../_service/jwt-utils.service';
import { AccessPointService } from '../_service/access-point.service';
import { EidasService } from '../_service/eidas-service.service';
import { Dialog } from '@angular/cdk/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import { AgreementComponent } from '../agreement/agreement.component';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '../_service/state.service';
import { OotsService } from '../_service/oots.service';
import { MultiLanguageStringPipe } from '../_model/multi-language-string';
import { QueryRequestData } from '../_model/queryRequestData';

declare const require: any;
const i18nIsoCountries = require("i18n-iso-countries");
@Component({
  selector: 'app-preview-space',
  templateUrl: './preview-space.component.html',
  styleUrls: ['./preview-space.component.css']
})
export class PreviewSpaceComponent implements OnInit {

  isUserLogged: boolean;
  isValidSession: boolean;
  currentUser: EidasUser;
  pdfLink = ""
  requestId: string;
  returnUrl: string;
  session: string;
  selectedLanguage: string;
  isChecked = false;
  requestInfo: QueryRequestData;

  //when editing experimental preview space, set this variable to true
  experimentalPreviewSpace = false;
  countries: any;
 
  constructor(
    private route: ActivatedRoute, 
    private jwtUtilService: JwtUtilsService, 
    private eidasService: EidasService, 
    private apService: AccessPointService,
    private dialog: Dialog, 
    public translate: TranslateService,
    private multiLanguagePipe: MultiLanguageStringPipe,
    private stateService: StateService,
    private ootsService: OotsService) {
   }

  ngOnInit(): void {
    const params = this.route.snapshot.queryParams;
    this.session = params['session'];
    this.returnUrl = params['returnurl'];
    //Condition for development phase of preview space
    if(window.location.href.indexOf("new") > -1){
      this.experimentalPreviewSpace = true;
    }
    if (this.session != undefined) {
      window.localStorage.setItem('session', this.session);
      this.requestId = this.session;
      window.localStorage.setItem('returnurl', this.returnUrl);
    } else {
      this.session = window.localStorage.getItem('session') ?? "";
      this.requestId = window.localStorage.getItem('session') ?? "";
      this.returnUrl = window.localStorage.getItem('returnurl') ?? "";
    }
    this.validateSession(this.session);
    this.isUserLogged = this.jwtUtilService.getCurrentUser() !== undefined;
  }

  agreementChange(event: any) {
    this.stateService.agreementAccepted = this.isChecked;
  }

  acceptOrDenyEvidence(accept: boolean) {
    console.log("peticionId: " + this.requestId + " accept: " + accept);
    this.apService.sendResponse({ peticion: this.requestId, accepted: accept }).subscribe(
      {
        next: result => {
          console.log(result);
        },
        error: (e) => {
          console.error(e)
        },
        complete: () => {
          console.log("completed")
        }
      }
    );
    this.navigateBack();
  }

  validateSession(session: string) {
    console.log("validating session: " + session);
    this.eidasService.isValidSession(session).subscribe(
      {
        next: result => {
          console.log(result);
          this.isValidSession = result;
          if (result) {
            this.eidasService.getRequestDataInfo(session).subscribe(data => {
              console.log(data);
              this.requestInfo = data
        
            });
            if(this.isUserLogged){
              this.apService.obtainEvidence(session).subscribe(data => {
                console.log(data);
                this.pdfLink = data
              });
            }
          }
        },
        error: (e) => {
          console.error("Error" + e)
        },
        complete: () => {
          console.log("completed")
        }
      }
    );
  }

  navigateBack() {
    window.location.href = this.returnUrl;
  }

  openAgreementDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.position = { top: '50%', left: '50%' };
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title: this.translate.instant('PREVIEW_SPACE.TERMS_AND_CONDITIONS_1'),
      message: this.translate.instant('PREVIEW_SPACE.TERMS_AND_CONDITIONS_2'),
    };
    const dialogRef = this.dialog.open(AgreementComponent, dialogConfig);
    dialogRef.closed.subscribe(result => {
      window.sessionStorage.setItem('agreementAccepted', 'true');
    });
  }
  transformTexts(collection:any[], lang: string){
    i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/es.json"));
    const translatedStr = this.multiLanguagePipe.transform(collection, lang) ?? "";
    return translatedStr;
  }

  changeCountryLanguage(countryCode: string, lang: string) {
    const countryName = i18nIsoCountries.getName(countryCode, lang);
    if(countryName === undefined){
      return countryCode;
    }
    else{
      return countryName;
    }
  }

  goToLink(link: string) {
    window.open(link, "_blank");
  }

}