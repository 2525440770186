import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class BreadcrumbService {

    private step = new BehaviorSubject(1);
    getStep = this.step.asObservable();

    getStepChange(): Observable<any>{ 
        return this.step.asObservable();
      }

    setStep(newStep: number){
        this.step.next(newStep)
    }

    private isChecked = new BehaviorSubject(true);
    getIsChecked = this.isChecked.asObservable();

    setIsChecked(check: boolean){
        this.isChecked.next(check);
    }
}